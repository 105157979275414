import axios, { AxiosError } from 'axios'
import { ApiResponse } from '../typings/api'
import { getToken } from '../utils/common'

export const publicRequest = async (method: 'POST' | 'GET', url: string, data?: any ): Promise<ApiResponse | undefined> => {
  let response;
  const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const apiUrl = process.env.REACT_APP_API_URL + url;
  const dataOrParams = ["GET"].includes(method.toUpperCase()) ? "params" : "data";
  const dataSend = data ?? {}
  const defaultConfig = { method, headers: defaultHeaders, url:apiUrl  };
  const config = {
    ...defaultConfig,
    [dataOrParams]: dataSend,
  };
  try {
    response = (await axios(config)).data;
  } catch (error: AxiosError | any) {
    console.log('dari api',error);
    if(error?.response?.data){
      throw new Error(error?.response?.data);
    }else{
      throw new Error('Something Wrong. Please Try Again');
    }
  }
  return response
}

export const authenticatedRequest = async (method: 'POST' | 'GET', url: string, data?: any ): Promise<ApiResponse | undefined> => {
  let response;
  const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Authorization": `Bearer ${getToken()}`,
  };
  const apiUrl = process.env.REACT_APP_API_URL + url;
  const dataOrParams = ["GET"].includes(method.toUpperCase()) ? "params" : "data";
  const dataSend = data ?? {}
  const defaultConfig = { method, headers: defaultHeaders, url:apiUrl  };
  const config = {
    ...defaultConfig,
    [dataOrParams]: dataSend,
  };
  try {
    response = (await axios(config)).data;
  } catch (error: AxiosError | any) {
    if(error?.response?.data){
      console.log(error?.response?.data)
      throw new Error(error?.response?.data);
    }else{
      throw new Error('Something Wrong. Please Try Again');
    }
  }
  return response
}

export const authenticatedRequestRaw = async (method: 'POST' | 'GET', url: string, data?: any ): Promise<ApiResponse | undefined> => {
  const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Authorization": `Bearer ${getToken()}`,
  };
  const apiUrl = process.env.REACT_APP_API_URL + url;
  const dataOrParams = ["GET"].includes(method.toUpperCase()) ? "params" : "data";
  const dataSend = data ?? {}
  const defaultConfig = { method, headers: defaultHeaders, url:apiUrl  };
  const config = {
    ...defaultConfig,
    [dataOrParams]: dataSend,
  };
  return axios(config)
}
export const uploadRequest = async (url: string,onProgress: Function, data?: any ): Promise<ApiResponse | undefined> => {
  const defaultHeaders = {
    "Content-Type": "multipart/form-data",
    "Authorization": `Bearer ${getToken()}`,
  };
  const apiUrl = process.env.REACT_APP_API_URL + url;
  const dataOrParams = "data";
  const dataSend = data ?? {}
  const defaultConfig = { method: "POST", headers: defaultHeaders, url:apiUrl  };
  const config = {
    ...defaultConfig,
    [dataOrParams]: dataSend,

  };
  return axios({...config,onUploadProgress: (event) => {
      if(event.total){
        const percentCompleted = Math.round((100 * event.loaded) / (event?.total || 1));
        onProgress(percentCompleted)
      }
    }})
}
