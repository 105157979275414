import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

const Communicator: React.FC<RouteComponentProps> = (props) => {
  return (
    <div className='d-flex w-100 h-100 align-items-center justify-content-center'>
      <h2>Coming Soon</h2>
    </div>
  )
}

export default Communicator;
