import logoKelase from '../../../assets/images/logo/logo-kelase-home.png';
import { BiBell } from 'react-icons/bi'
import { FaChevronLeft } from 'react-icons/fa'
import './header.scss';
import { useHistory } from 'react-router-dom'
interface IHeader{
  title?:string;
  onBack?: Function
}
const Header = (props: IHeader) => {
  const history = useHistory();
  const goBack = () =>{
    if(props.onBack){
      props.onBack();
    }else{
      history.goBack();
    }
  }
  return (
    <div className='header header-common'>
      <a className='icon-back' onClick={goBack.bind(this)}>
        <FaChevronLeft />
      </a>
      <h4 className='header-title mb-0'>{ props.title }</h4>
    </div>
  );
}
export default Header

export const HeaderHome = () => {
  return (
    <div className='header header-dashboard'>
      <img src={logoKelase} alt='logo' className='logo' />
      <a className='icon-notification'>
        <BiBell />
      </a>
    </div>
  );
}
