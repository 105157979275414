import React, { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query';
import './styles/style.scss';
import { Routes } from './router/routes'
import 'react-block-ui/style.css';
import BlockUi from 'react-block-ui'
import { connect } from 'react-redux'
import { getToken, getUser, isAuthenticated } from './utils/common'
import { UseAppDispatch } from './redux/store'
import { SET_USER } from './redux/types'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'dayjs/locale/id'
import localizeFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from "dayjs";
dayjs.extend(localizeFormat)
dayjs.extend(relativeTime)
dayjs.locale('id')
interface AppProps{
  UI?: any;
}

const queryClient = new QueryClient({});
const App:(props: AppProps) => JSX.Element = (props) => {
  const dispatch = UseAppDispatch();
  const [blocking, setBlocking] = useState(false);
  const [loaded, setLoaded] = useState(false);
  useEffect(()=>{
    setBlocking(props.UI.loading);
  },[props.UI.loading])
  useEffect(()=>{
    if(isAuthenticated()){
      const user = getUser();
      const token = getToken();
      dispatch({
        type:SET_USER,
        payload:{
          profile:user,
          token,
        }
      })
    }
    setLoaded(true)
  },[])
  return (
    <QueryClientProvider client={queryClient}>
      <BlockUi tag="div" blocking={blocking} message={props.UI.messageLoading ?? "Loading, please wait"}>
        <div className="main-wrapper">
          {loaded && <Routes />}
          <ToastContainer />
        </div>
      </BlockUi>
    </QueryClientProvider>
  );
}
const mapStateToProps = (state: any) => {
  return {
    UI: state.UI,
  };
};
export default connect(mapStateToProps,null)(App);
