import './bottom-nav.scss';
import {BiBook, BiHome, BiUser} from 'react-icons/bi'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {BsPersonBoundingBox} from "react-icons/bs";

interface IBottomNav{
  menu: string;
}
const BottomNav = (props: IBottomNav) => {
  const [activeMenu, setActiveMenu] = useState(props.menu);
  const history = useHistory();
  const goTo = (menu: string) =>{
    setActiveMenu(menu);
    if(menu === 'course'){
      history.push(`/account/my-course`)
    }else{
      history.push(`/${menu}`)
    }

  }
  return (
    <div className='bottom-nav-container'>
      <div className='bottom-nav-content'>
        <button className={`bottom-nav-button ${activeMenu === 'dashboard'?'active':''}`} onClick={()=>goTo('dashboard')}>
          <BiHome/>
          <span>Beranda</span>
        </button>
        <button className={`bottom-nav-button ${activeMenu === 'course'?'active':''}`} onClick={()=>goTo('course')}>
          <BiBook/>
          <span>Kursus Saya</span>
        </button>
        <button className={`bottom-nav-button ${activeMenu === 'competency'?'active':''}`} onClick={()=>goTo('competency')}>
          <BsPersonBoundingBox/>
          <span>Tes Kompetensi</span>
        </button>
        <button className={`bottom-nav-button ${activeMenu === 'account'?'active':''}`} onClick={()=>goTo('account')}>
          <BiUser/>
          <span>Akun</span>
        </button>
      </div>
    </div>
  );
}
export default BottomNav;
