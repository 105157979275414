import React, {useEffect} from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { InitialStateUIType, InitialStateUserType } from '../../typings/state'
import {connect, useDispatch} from 'react-redux'
import { logout } from '../../redux/actions/userAction'
import './account.scss';
import {BiBook, BiUser, BiLogOut, BiCart} from 'react-icons/bi'
import { FaChevronRight } from 'react-icons/fa'
import {useQuery} from "react-query";
import {authenticatedRequestRaw} from "../../services/api.service";
import {LOADING_UI, UNLOADING_UI} from "../../redux/types";
import {UserType} from "../../typings/user";
import {BsPersonBoundingBox} from "react-icons/bs";
const menu = [
  {
    key: 'profile',
    label: 'Sunting Profil',
    path:'/account/profile',
    icon: BiUser
  },
  {
    key: 'course',
    label: 'Kursus Saya',
    path:'/account/my-course',
    icon: BiBook
  },
  {
    key: 'competency',
    label: 'Tes Kompetensi Saya',
    path:'/account/my-competencies',
    icon: BsPersonBoundingBox
  },
  {
    key: 'transaction',
    label: 'Transaksi',
    path:'/transaction',
    icon: BiCart
  },
]
interface AccountProps extends RouteComponentProps{
  user: InitialStateUserType;
  UI: InitialStateUIType;
  logout: Function;
}
const Account: React.FC<AccountProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: profile, isLoading} = useQuery<UserType, boolean>('profileDetail', async () => {
    const response = await authenticatedRequestRaw('GET','/profile/detail')
    return response?.data?.data
  })
  useEffect(() => {
    dispatch({ type: isLoading? LOADING_UI : UNLOADING_UI })
  },[isLoading, dispatch])
  const onLogout = async () => {
    await props?.logout();
  }
  const goTo = (path: string) => {
    history.push(path)
  }

  const BadgePendingTrans = () => {
    if(profile && profile?.pending_transaction){
      if( profile?.pending_transaction > 0){
        return (
          <span className="badge rounded-pill text-bg-danger me-2">{profile?.pending_transaction}</span>
        )
      }
    }
    return null
  }
  const BadgeMyCourse = () => {
    if(profile){
      return (
        <span className="badge rounded-pill text-bg-primary me-2">{profile?.my_courses}</span>
      )
    }
    return null
  }

  const BadgeMyCompetencies = () => {
    if(profile){
      return (
        <span className="badge rounded-pill text-bg-primary me-2">{profile?.my_competencies}</span>
      )
    }
    return null
  }

  return (
    <>
      <div className='profile-header d-flex flex-column align-items-center pb-4 mb-4 border-bottom w-100'>
        <img src={profile?.avatar ?? 'https://placehold.co/120x120' }
             className='profile-header-image mb-3 rounded-circle' alt='logo'/>
        <h5 className='fw-bold mb-2'>{profile?.fullname}</h5>
        <span className=''>{profile?.email}</span>
      </div>
      <ul className='list-group w-100 list-menu'>
        {menu.map((m,index) => {
          return (
            <li
              className='list-group-item d-flex justify-content-between list-menu-item align-items-center cursor-pointer'
              key={`menu${index}`}
              onClick={() => goTo(m.path)}
            >
              <div className='d-flex align-items-center'>
                <m.icon size={'1.5rem'}/>
                <span className='mx-3'>{m.label}</span>
              </div>
              <div className='d-flex align-items-center'>
                {
                  m.key === 'transaction'? <BadgePendingTrans /> : null
                }
                {
                  m.key === 'course'? <BadgeMyCourse /> : null
                }
                {
                  m.key === 'competency'? <BadgeMyCompetencies /> : null
                }
                <FaChevronRight />
              </div>
            </li>
          );
        })}
        <li
          className='list-group-item d-flex justify-content-between list-menu-item align-items-center cursor-pointer'
          onClick={onLogout.bind(this)}
        >
          <div className='d-flex align-items-center'>
            <BiLogOut size={'1.5rem'}/>
            <span className='mx-3'>Keluar</span>
          </div>
          <FaChevronRight />
        </li>
      </ul>
    </>
  )
};
const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    UI: state.UI,
  };
};
const mapDispatchToProps = { logout };
export default connect(mapStateToProps,mapDispatchToProps)(Account);
