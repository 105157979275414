import * as React from "react";
import { BiSearch, BiXCircle } from 'react-icons/bi'
import { ForwardedRef, useEffect, useState } from 'react'

interface IInputSearch {
  placeholder: string;
  type?: string;
  className?: string;
  defaultValue?: any;
  block?: boolean;
  onChange: Function;
  onEnter: Function;
  onClear?: Function;
}

const InputSearch = React.forwardRef(({
  placeholder,
  type = "text",
  className = "",
  defaultValue,
  onChange,
  onEnter,
  onClear,
}: IInputSearch,_: ForwardedRef<any>) => {
  const [values, setValues] = useState(defaultValue || '');
  useEffect(() => {
    setValues(defaultValue || '');
  }, [defaultValue])
  let styleInput = `form-control w-100 ${className}`;
  let styleWrapper = `input-wrapper input-group-icon icon-right w-100 mb-3`;

  return (
    <div className={styleWrapper}>
      <input
        className={styleInput}
        placeholder={placeholder}
        type={type}
        value={values}
        onChange={(event)=>{
          onChange(event.target.value);
        }}
        onKeyDown={event => {
          if (event.key === 'Enter' || event.keyCode === 13) {
            onEnter(event);
          }
        }}
        ref={_}
      />
      {values.length>0?<BiXCircle size={'1.25rem'} className={'icon'} onClick={()=>{
        if(onClear){
          setValues('');
          onClear();
        }
      }} />:<BiSearch size={'1.25rem'} className={'icon'} />}
    </div>
  );
});
export default InputSearch;
