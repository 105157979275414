import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { InitialStateUserType } from '../typings/state'
import BottomNav from './Layout/BottomNav'
import Header from './Layout/Header/header'
interface IMainContainerProps {
  component: any;
  menu:string;
  user?: InitialStateUserType;
  path?: string;
  exact?: boolean;
  excludeBottom?: boolean
  noPadding?: boolean
  excludeHeader?: boolean
  headerTitle?: string
}

const AuthedRoutesContainer: React.FC<IMainContainerProps> = (props) => {
  const { component: Component, ...rest } = props;
  return <Route {...rest} render={matchProps =>
    (!props?.user?.authenticated) ?
      (
        <Redirect to="/" />
      ) :
      (
        <>
          {props?.excludeHeader ? null : <Header title={props?.headerTitle} />}
          <div className={`main-content ${props?.excludeBottom?'':'with-bottom'} ${props?.noPadding?'p-0':''}`}>
            <Component {...matchProps} />
          </div>
          {props?.excludeBottom ? null : <BottomNav menu={props.menu} />}
        </>
      )
  } />
}

export const AuthedRoutes = connect(
  (state: any) => {
    return ({ user: state.user });
  },null
)(AuthedRoutesContainer);
