import {ActivityType} from "../../../../typings/activity";
import React, {useEffect, useState} from "react";
import Button from "../../../../components/UI/Button";
import {BiFile, BiXCircle} from 'react-icons/bi'
import './task.scss'
import {humanFileSize} from "../../../../utils/common";
import {useDispatch} from "react-redux";
import {uploadRequest} from "../../../../services/api.service";
import {toast} from 'react-toastify'
import {useHistory} from "react-router-dom";

interface ITask {
	activity: ActivityType
}
const Task: React.FC<ITask> = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [file, setFile] = useState<any>(null);
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [uploadingMsg, setUploadingMsg] = useState<string>('');
	const [uploadPercentage, setUploadPercentage] = useState<number>(0);
	const onChangeFile = (event: any) => {
		if(event.target.files.length > 0){
			const file = event.target.files[0]
			setFile(file)
		}
	}

	const removeFile = () => {
		setFile(null)
	}

	const DisplayFile = () => {
		if(file){
			return <div className={'d-flex flex-column w-100 align-items-center'}>
				<div className={'d-flex w-75 border border-dark bg-light rounded-2 p-3 position-relative mb-3'} style={{minHeight: '50px'}}>
					{ !isUploading && <BiXCircle size={'1.5rem'} style={{fill:'var(--bs-danger)'}} className={'position-absolute cursor-pointer top-0 end-0 mt-2 me-2'} onClick={removeFile}/> }
					<div className={'d-flex justify-content-center align-items-center me-3'}>
						<BiFile size={'3rem'} />
					</div>
					<div className={'d-flex flex-column justify-content-center'}>
						<span className={'fw-bold'} style={{wordBreak:'break-all'}}>{file?.name}</span>
						<small className={'fs-7'}>{ humanFileSize(file?.size, true) }</small>
					</div>
				</div>
				{ !isUploading && <Button primary onClick={onSubmit}>Unggah</Button> }
				<div className='d-flex flex-column w-100 mt-3'>
					<div className="progress w-100">
						<div className="progress-bar" style={{width:`${uploadPercentage}%`}} role="progressbar" aria-valuenow={uploadPercentage}
						     aria-valuemin={0} aria-valuemax={100}></div>
					</div>
					<div className="d-flex justify-content-center mt-2">
						<span className="fst-italic">{uploadingMsg}</span>
					</div>
				</div>
			</div>
		}
		return null
	}
	const onSubmit = async () => {
		if(props.activity){
			setIsUploading(true)
			// dispatch({ type: LOADING_UI })
			const [fileName, fileExt] = file.name.split('.')
			const formData = new FormData();
			formData.append('file', file)
			formData.append('file_size', file.size)
			formData.append('file_name', file.name)
			formData.append('file_ext', fileExt)
			formData.append('activity_id', props.activity.id.toString())
			formData.append('course_id', props.activity.course_id.toString())
			await uploadRequest('/course/activity/submit',(percent: number) => {
				setUploadingMsg(percent>=100 ? 'Menyimpan Data...' : `Uploading: ${percent}%`)
				setUploadPercentage(percent)
			},formData)
			history.push(`/course/${props.activity?.course_id}`)
			toast.success("Berhasil mengirim data tugas", {
				position: toast.POSITION.TOP_CENTER
			});
		}
	}
	return (
		<div className={'d-flex flex-column w-100'}>
			<h2 className="fw-bold mb-3">{props?.activity?.title}</h2>
			<div className={'border p-3 rounded-2 position-relative mb-5'}>
				<div dangerouslySetInnerHTML={{ __html: props?.activity?.description || '' }} />
			</div>
			{
				file === null && (<div className={'w-100 d-flex justify-content-center position-relative'}>
					<div className={'position-relative w-50'}>
						<div className={'task-file'}>
							<input type="file" name={'task_file'} className={'task-file'} id="upload-task" onChange={onChangeFile.bind(this)} />
							<label htmlFor="upload-task">
								<Button outlinePrimary>
									Pilih Berkas
								</Button>
							</label>
						</div>
					</div>
				</div>)
			}

			{
				file && <DisplayFile />
			}
		</div>
	)
}
export default Task
