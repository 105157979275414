import { Link, RouteComponentProps } from 'react-router-dom'
import { InitialStateUIType, InitialStateUserType } from '../../typings/state'
import React, {useEffect, useState} from 'react'
import { useQuery } from 'react-query'
import { logout } from '../../redux/actions/userAction'
import {connect, useDispatch} from 'react-redux'
import './dashboard.scss'
import { HeaderHome } from '../../components/Layout/Header/header'
import InputSearch from '../../components/UI/InputSearch'
import CourseCard, { CourseCardFeatured } from '../../components/Layout/Course/CourseCard'
import CourseCategoryFilter from '../../components/Layout/Course/CourseCategoryFilter'
import {authenticatedRequestRaw} from '../../services/api.service'
import {LOADING_UI, UNLOADING_UI} from "../../redux/types";
import {Course, CourseCategory} from "../../typings/course";

interface DashboardProps extends RouteComponentProps{
  user: InitialStateUserType;
  UI: InitialStateUIType;
}

const Dashboard: React.FC<DashboardProps> = (props) => {
  const dispatch = useDispatch();
  const inputRef: React.RefObject<HTMLElement> = React.createRef()
  const [querySearch, setQuerySearch] = useState('')
  const [category, setCategory] = useState<number | null>(null)
  const [categories, setCategories] = useState<CourseCategory[]>([])
  const [courses, setCourses] = useState<Course[]>([])
  const { data: dataCategories, isLoading: loadingCategories } = useQuery('categories', async () => {
    const response = await authenticatedRequestRaw('GET','/course/categories')
    return response?.data?.data
  })
  const { data: dataCourses, refetch, isFetching } = useQuery('courses', async () => {
    const params: any = {
      perPage: 4
    }
    if(category){
      params['category'] = category;
    }
    const response = await authenticatedRequestRaw('POST','/course/list',params)
    return response?.data?.data
  },{enabled: false})

  useEffect(() => {
    if(dataCategories){
      setCategories([{
        id:null,
        name: 'Semua'
      },...dataCategories])
    }
  },[dataCategories])
  useEffect(() => {
    if(dataCourses){
      setCourses([...dataCourses])
    }
  },[dataCourses])
  useEffect(() => {
    if(loadingCategories || isFetching){
      dispatch({ type: LOADING_UI })
    }else{
      dispatch({ type: UNLOADING_UI })
    }
  },[loadingCategories, isFetching, dispatch])
  useEffect(() => {
    refetch();
  },[category])
  const handleQuerySearch = (value: string) => {
    setQuerySearch(value)
  }
  const clearQuerySearch = () => {
    setQuerySearch('')
  }
  const enterSearch = () => {
    inputRef?.current?.blur();
    props?.history?.push(`/course?q=${encodeURI(querySearch)}`);
  }
  const setCategoryFilter = (categoryId: number | null) => {
    setCategory(categoryId)
  }
  return (
    <>
      <HeaderHome />
      <InputSearch
        placeholder={'Cari Kursus Disini'}
        onChange={handleQuerySearch.bind(this)}
        onEnter={enterSearch.bind(this)}
        onClear={clearQuerySearch.bind(this)}
        defaultValue={querySearch}
        ref={inputRef}
      />
      <div className='d-flex w-100 justify-content-between align-items-center mb-2'>
        <h5 className='fw-bold'>Kursus Terbaru</h5>
        <Link to="/course" className='fw-bold fs-7 text-primary'>
          Lihat Semua
        </Link>
      </div>
      <CourseCategoryFilter categories={categories} category={category} onClick={(catId: number | null) => {
        setCategoryFilter(catId);
      }} />
      {
        courses.map(c => {
          return (
            <CourseCard key={c.id} {...c}></CourseCard>
          )
        })
      }
    </>
  )
}
const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    UI: state.UI,
  };
};
const mapDispatchToProps = { logout };
export default connect(mapStateToProps,mapDispatchToProps)(Dashboard);
