import React, {useEffect, useState} from "react";
import {RouteComponentProps, useHistory, useParams} from "react-router-dom";
import {InitialStateUIType, InitialStateUserType} from "../../typings/state";
import {CompetencyTestSection, CompetencyTestType} from "../../typings/competency-test";
import {useDispatch, useSelector} from "react-redux";
import {useQuery} from "react-query";
import {authenticatedRequest, authenticatedRequestRaw} from "../../services/api.service";
import {toast} from "react-toastify";
import Header from "../../components/Layout/Header/header";
import {LOADING_UI, UNLOADING_UI} from "../../redux/types";
import Button from "../../components/UI/Button";
import dayjs from 'dayjs'
import {MemberAnswerType} from "../../typings/activity";

interface CompetencyTestProps extends RouteComponentProps{
	user: InitialStateUserType;
	UI: InitialStateUIType;
}
const Detail: React.FC<CompetencyTestProps> = (props) => {
	const history  = useHistory();
	const dispatch = useDispatch();
	const params:any  = useParams();
	const [testId, setTestId] = useState<number | null>(null);
	const [competency, setCompetency] = useState<CompetencyTestType | null>(null)
	const user = useSelector((state: {user: InitialStateUserType}) => state.user)
	const { data, refetch, isFetching } = useQuery('competencyDetail', async () => {
		try{
			const response = await authenticatedRequest('GET','/competency-test/' + testId)
			return response?.data
		}catch (e: any) {
			toast.error(e?.message ?? 'Terjadi Kesalahan. Silahkan coba lagi beberapa saat', {
				position: toast.POSITION.TOP_CENTER
			});
			history.push(`/competency`)
		}
		return null
	}, {enabled: false})
	useEffect(() => {
		if(isNaN(params?.id)){
			history.push('/dashboard')
		}
		setTestId(Number(params?.id));
	},[]) // eslint-disable-line react-hooks/exhaustive-deps
	useEffect(() => {
		if(testId){
			refetch();
		}
	},[testId]);
	useEffect(() => {
		if(data){
			// @ts-ignore
			setCompetency(data)
		}
	},[data]);
	useEffect(() => {
		if(isFetching){
			dispatch({ type: LOADING_UI })
		}else{
			dispatch({ type: UNLOADING_UI })
		}
	},[isFetching, dispatch])
	const startTest = async () => {
		dispatch({type: LOADING_UI})
		await authenticatedRequest('POST', '/competency-test/pretest',{
			id: testId
		})
		dispatch({type: UNLOADING_UI})
		history.push(`/competency/${testId}/test`)
	}
	const getDuration = (start_date: string, end_date: string) => {
		const startDate = dayjs(start_date)
		const endDate = dayjs(end_date)
		const diffMinute = endDate.diff(startDate,'minutes');
		const minuteSecond = diffMinute * 60;
		const diffSecond = endDate.diff(startDate,'second');
		return <>
			{diffMinute} Menit {diffSecond - minuteSecond} Detik
		</>
	}
	const calculateScoreSection = (section: CompetencyTestSection) => {
		const qIds = section?.questions.map(q => q.id)
		const memberAnswer: MemberAnswerType[] = JSON.parse(competency?.memberTest?.member_answer as any) as MemberAnswerType[]
		const filterAnswer = memberAnswer.filter(ma => qIds.includes(ma.id))
		const score = filterAnswer.reduce(function (sc, item) { return Number(sc) + Number(item.score) }, 0.0);
		return <tr>
			<td>{section.name}</td>
			<td className='fw-bold'>{score}</td>
		</tr>
	}
	const DisplayButton = () => {
		if(competency?.memberTest){
			if(!competency?.memberTest?.end_date){
				return (
					<>
						<div className='alert alert-warning mb-4'>
							Anda masih melakukan test ini. klik tombol dibawah ini untuk melanjutkan.
						</div>
						<Button primary onClick={() => history.push(`/competency/${testId}/test`)} className='w-75'>
							Lanjutkan Test
						</Button>
					</>
				)
			}else{
				return <div className='w-100 d-flex flex-column'>
					{
						competency?.memberTest?.member_answer &&
						<>
							<div className='alert alert-success mb-3 w-100 d-flex justify-content-center'>
								Anda telah menyelesaikan tes ini.
							</div>
              <div className='card d-flex flex-column align-items-center w-100 mb-3'>
                <div className='card-header w-100 text-center bg-white'>
                  <span className={'card-title bg-white fw-bold'}>Nama</span>
                </div>
                <div className='card-body'>
                  <div className='fw-bold'>
	                  { user.profile?.fullname }
                  </div>
                </div>
              </div>
						</>
					}
					<div className='d-flex flex-column w-100' style={{gap: '16px'}}>
						<div className='card d-flex flex-column align-items-center w-100'>
							<div className='card-header w-100 text-center bg-white'>
								<span className={'card-title bg-white fw-bold'}>Nilai</span>
							</div>
							{
								competency?.memberTest?.member_answer ? <div className='card-body w-100 p-0'>
									{/*<span className='fw-bold' style={{fontSize: '48px',lineHeight:1}}>{competency.memberTest.score}</span>*/}
                  <table className='table table-bordered w-100'>
                    <tbody>
										{
											competency?.sections?.map(s => {
												return calculateScoreSection(s)
											})
										}
                    <tr>
                      <td className='fw-bold'>Total Nilai</td>
                      <td className='fw-bold'>{competency.memberTest.score}</td>
                    </tr>
                    </tbody>
                  </table>
								</div> : <div className='card-body w-100 flex justify-content-center'>
									<p className='text-center text-danger'>Anda tidak menyelesaikan test ini</p>
								</div>
							}

						</div>
						{
							competency?.memberTest?.member_answer && <>
                <div className='card d-flex flex-column align-items-center w-100'>
                  <div className='card-header w-100 text-center bg-white'>
                    <span className={'card-title bg-white fw-bold'}>Waktu Pengerjaan</span>
                  </div>
                  <div className='card-body'>
                    <div className='fw-bold fs-7'>
	                    { competency.memberTest.start_date } - { competency.memberTest.end_date }
                    </div>
                  </div>
                </div>
								<div className='card d-flex flex-column align-items-center w-100'>
									<div className='card-header w-100 text-center bg-white'>
										<span className={'card-title bg-white fw-bold'}>Durasi</span>
									</div>
									<div className='card-body'>
										<div className='fw-bold'>
											{getDuration(competency.memberTest.start_date,competency.memberTest.end_date)}
										</div>
									</div>
								</div>
							</>
						}
					</div>
				</div>;
			}
		}
		return (
			<Button primary onClick={startTest} className='w-75'>
				Mulai
			</Button>
		)
	}
	return <>
		<Header title={competency?.title} onBack={() => {
			history.push(`/competency`)
		}} />
		<div className={'main-content w-100'}>
			<img src={competency?.image ?? 'https://placehold.co/200x200'}
			     alt={'logo'} className='mb-4 w-100'
			/>
			<p className='text-start w-100 mb-4'>{ competency?.description }</p>
			<p className='text-start w-100 mb-4'>
				Tes kompetensi ini terdiri dari <strong>{competency?.sections?.length ?? 0}</strong>&nbsp;Bagian dengan durasi <strong>{competency?.duration} Menit</strong>
				&nbsp;dan total soal <strong>{competency?.totalQuestions} Soal</strong>
			</p>
			<DisplayButton />
		</div>
	</>
}

export default Detail
