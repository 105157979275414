import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useQuery} from "react-query";
import {authenticatedRequestRaw} from "../../services/api.service";
import {LOADING_UI, UNLOADING_UI} from "../../redux/types";
import {RouteComponentProps} from "react-router-dom";
import {InitialStateUIType, InitialStateUserType} from "../../typings/state";
import {CompetencyTestType} from "../../typings/competency-test";
import CompetencyCard from "../../components/Layout/Competency/CompetencyCard";
interface CompetencyTestProps extends RouteComponentProps{
	user: InitialStateUserType;
	UI: InitialStateUIType;
}
const MyCompetencies: React.FC<CompetencyTestProps> = (props) => {
	const dispatch = useDispatch();
	const [competency, setCompetency] = useState<CompetencyTestType[]>([])
	const { data: dataCompetency, refetch, isFetching } = useQuery('my-competency-test', async () => {
		const response = await authenticatedRequestRaw('GET','/competency-test/my')
		return response?.data?.data
	},{enabled: false})
	const inputRef: React.RefObject<HTMLElement> = React.createRef();
	useEffect(() => {
		if(dataCompetency){
			setCompetency([...dataCompetency])
		}
	},[dataCompetency])
	useEffect(() => {
		if(isFetching){
			dispatch({ type: LOADING_UI })
		}else{
			dispatch({ type: UNLOADING_UI })
		}
	},[isFetching, dispatch])
	useEffect(() => {
		refetch()
	}, [])
	return <>
		{
			competency.map(c => {
				return (
					<CompetencyCard key={c.id} isMy={true} {...c}></CompetencyCard>
				)
			})
		}
	</>
}

export default MyCompetencies
