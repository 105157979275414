import React, {useEffect, useState} from 'react'
import {RouteComponentProps, useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {useQuery} from "react-query";
import {authenticatedRequestRaw} from "../../services/api.service";
import './my-transaction.scss'
import {TransactionDetail} from "../../typings/transaction";
import {
  PAYMENT_CANCELED,
  PAYMENT_EXPIRED,
  PAYMENT_FAILED,
  PAYMENT_PENDING,
  PAYMENT_SUCCESS, PM_CC, PM_GOPAY, PM_QRIS, PM_SHOPEE, PM_VA_BCA, PM_VA_BNI, PM_VA_BRI
} from "../../constant/payment";
import {LOADING_UI, UNLOADING_UI} from "../../redux/types";
import {BiBook} from "react-icons/bi";
import {BEUrl, imagePath} from "../../utils/common";
import dayjs from 'dayjs'
import Button from "../../components/UI/Button";
import gopayIcon from "../../assets/images/logo/gopay.png";
import shopeeIcon from "../../assets/images/logo/shopeepay.png";
import qrisIcon from "../../assets/images/logo/qris.png";
import ccIcon from "../../assets/images/logo/cc.png";
import bcaIcon from "../../assets/images/logo/bca.png";
import briIcon from "../../assets/images/logo/bri.png";
import bniIcon from "../../assets/images/logo/bni.png";

const MyTransaction: React.FC<RouteComponentProps> = (props) => {
  const history  = useHistory();
  const dispatch = useDispatch();
  const [tabActive, setTabActive] = useState<number>(0);
  const [transactionList, setTransactionList] = useState<TransactionDetail[]>([]);
  const { data: dataCategory, refetch: refetchCategory, isFetching: isFetchingCategory, isLoading } = useQuery('transaction-category', async () => {
    const response = await authenticatedRequestRaw('GET','/transaction/category')
    return response?.data?.data
  })
  const { data, refetch, isFetching } = useQuery<TransactionDetail[] | undefined>(['transaction-list',tabActive, dataCategory], async () => {
    if(dataCategory){
      const response = await authenticatedRequestRaw('GET','/transaction/list/' + tabActive)
      return response?.data?.data as TransactionDetail[]
    }else{
      return []
    }
  },{enabled: false})
  useEffect(() => {
    if(isLoading || isFetching){
      dispatch({ type: LOADING_UI })
    }else{
      dispatch({ type: UNLOADING_UI })
    }
  },[isLoading, isFetching, dispatch])
  useEffect(()=>{
    if(dataCategory){
      refetch().then((res) => {
        if(res?.data){
          setTransactionList(res.data)
        }
      })
    }
  },[tabActive,dataCategory])
  const getCategoryLabel = (catName: string) => {
    const [prefix,status] = catName.split('_');
    switch (Number(status)) {
      case PAYMENT_PENDING:
        return 'Menunggu'
      case PAYMENT_SUCCESS:
        return 'Berhasil'
      case PAYMENT_FAILED:
        return 'Gagal'
      case PAYMENT_CANCELED:
        return 'Batal'
      case PAYMENT_EXPIRED:
        return 'Kadaluarsa'
    }
  }
  const setCategoryFilter = (idx: number) => {
    setTabActive(idx)
  }
  const BadgeStatus = (props: {status: number}) => {
    switch (Number(props.status)) {
      case PAYMENT_PENDING:
        return <span className="badge bg-primary mb-2">Menunggu</span>
      case PAYMENT_SUCCESS:
        return <span className="badge bg-success mb-2">Berhasil</span>
      case PAYMENT_FAILED:
        return <span className="badge bg-danger mb-2">Gagal</span>
      case PAYMENT_CANCELED:
        return <span className="badge bg-secondary mb-2">Batal</span>
      case PAYMENT_EXPIRED:
        return <span className="badge bg-warning mb-2">Kadaluarsa</span>
    }
    return null
  }

  const downloadReceipt = (uid: string) => {
    window.location.replace(`${BEUrl()}static/pdf/${uid}.pdf`)
  }

  const PaymentMethod = (props: { transaction: TransactionDetail }) => {
    const transaction = props.transaction;
    if(transaction){
      let data = {
        label: '',
        icon: ''
      }
      switch (transaction.payment_method) {
        case PM_GOPAY:
          data.icon = gopayIcon;
          data.label = 'Gopay';
          break;
        case PM_SHOPEE:
          data.icon = shopeeIcon;
          data.label = 'ShopeePay';
          break;
        case PM_QRIS:
          data.icon = qrisIcon;
          data.label = 'QRIS';
          break;
        case PM_CC:
          data.icon = ccIcon;
          data.label = 'Kartu Kredit';
          break;
        case PM_VA_BCA:
          data.icon = bcaIcon;
          data.label = 'BCA Virtual Account';
          break;
        case PM_VA_BRI:
          data.icon = briIcon;
          data.label = 'BRI Virtual Account';
          break;
        case PM_VA_BNI:
          data.icon = bniIcon;
          data.label = 'BNI Virtual Account';
          break;
      }
      return <>
        <div className={'d-flex align-items-center'} style={{gap:'10px'}}>
          <img src={data.icon} style={{width:'1.5rem'}}/>
          <span className={'fw-bold fs-7'} style={{wordBreak:'break-all'}}>{data.label}</span>
        </div>
      </>
    }
    return null
  }

  const TransAction = (props: {transaction: TransactionDetail}) => {
    const transaction = props.transaction
    switch (Number(transaction.status)) {
      case PAYMENT_PENDING:
        return (
          <>
            <div className={'d-flex justify-content-between'} style={{gap:'10px'}}>
              <Button className={'fs-7 custom-btn'} outlinePrimary rounded onClick={()=>history.push(`/payment/wait/${transaction.uid}`)}>Lanjut Pembayaran</Button>
            </div>
          </>
        )
      case PAYMENT_SUCCESS:
        return (
          <>
            <div className={'d-flex'} style={{gap:'10px'}}>
              <Button className={'fs-7 custom-btn'} primary rounded onClick={() => downloadReceipt(transaction.uid)} >Unduh Kuitansi</Button>
            </div>
          </>
        )
    }
    return null
  }
  return (
    <>
      <div className='status-list my-2'>
        {
          dataCategory && Object.keys(dataCategory).map((c, idx) => (
            <div className={`status-list-item ${idx === tabActive ? 'active' : ''} `} onClick={()=>setCategoryFilter(idx)} key={`trans-cat-${idx}`}>
              {getCategoryLabel(c)}
              <span className={'badge bg-danger ms-3'}>{dataCategory[c]}</span>
            </div>
          ))
        }
      </div>
      {
        transactionList && transactionList.map((t, idx2) => (
          <div className='card-course'  key={`trans-list-${tabActive}-${idx2}`}>
            <div className={'d-flex flex-column w-100'}>
              <div className={'d-flex w-100 mb-2'}>
                <PaymentMethod  transaction={t}/>
              </div>
              <div className={'d-flex mb-3 flex-column'}>
                <span className={'fs-7 mb-1'}>Tanggal: {dayjs(t.created_at).format('LLLL')}</span>
                <span className={'fs-7 text-muted'}>#{t.uid}</span>
              </div>
              <div className='cc-content'>
                <div className='cc-image'>
                  <img src={imagePath('course', t.course?.logo || '')}
                       alt={'logo'}
                  />
                </div>
                <div className='cc-content-info'>
                  <BadgeStatus status={t.status} />
                  <h6 className='fw-bold mb-1 cc-title'>{t.course?.name}</h6>
                  <div className='cc-price fw-bold mb-2'>
                    Rp.{ t.final_amount.toLocaleString('ID-id') }
                  </div>
                </div>
              </div>
              <div className={'border border-bottom w-100 my-3'}></div>
              <TransAction transaction={t} />
            </div>

          </div>
        ))
      }
      {
        transactionList.length === 0 && (
          <div className={'d-flex w-100 justify-content-center align-items-center'} style={{
            height:'70vh'
          }}>
            <p className={'w-75 fw-bold text-center text-muted'}>Tidak Ada Transaksi</p>
          </div>
        )
      }
    </>
  )
}
export default MyTransaction
