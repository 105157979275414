import * as React from "react";
import {
	RouteComponentProps,
	Switch,
	useRouteMatch,
} from "react-router-dom";
import MyTransaction from "../pages/transaction/my-transaction";
import { AuthedRoutes } from "../components/AuthedRoutes";
export const transactionRoutes = (props: RouteComponentProps) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	let { path } = useRouteMatch();
	return (
		<>
			<Switch>
				<AuthedRoutes
					menu="transaction"
					path={`${path}/`}
					exact={true}
					component={MyTransaction}
					headerTitle="Transaksi Saya"
				></AuthedRoutes>
			</Switch>
		</>
	);
};
