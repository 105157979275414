import * as React from 'react'

export interface IButton {
  className?: string
  type?: 'submit'|'button'|null
  onClick?: Function
  primary?: boolean
  danger?: boolean
  secondary?: boolean
  info?: boolean
  outlinePrimary?: boolean
  block?: boolean
  disabled?: boolean
  rounded?: boolean
}
const Button: React.FC<React.PropsWithChildren<IButton>> = (props) => {
  let style = `btn`;
  if(props.primary){
    style+= ' btn-primary'
  }else if(props.secondary){
    style+= ' btn-secondary'
  }else if(props.info){
    style+= ' btn-info'
  }else if(props.outlinePrimary){
    style+= ' btn-outline-primary'
  }else if(props.danger){
    style+= ' btn-danger'
  }
  if(props.block){
    style+= ' w-100';
  }
  if(props.rounded){
    style+= ' rounded';
  }
  let classButton = `${style} ${props.className?props.className:''}`;
  let button = <button className={classButton} disabled={props?.disabled} >{props.children}</button>
  if(props.onClick){
    const onClick = props.onClick;
    button = <button
      className={classButton}
      disabled={props?.disabled}
      onClick={(event)=>{
        onClick(event);
        }
      }
    >
      {props.children}
    </button>
  }
  return button;
}
export default Button;
