import React, {LegacyRef, useEffect, useRef} from "react";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {toast} from 'react-toastify'
import { useForm } from 'react-hook-form'
import {InitialStateUserType} from "../../typings/state";
import AuthHeader from '../../components/Layout/Auth/Header'
import {FaEnvelope, FaKey} from 'react-icons/fa'
import './auth.scss';
import Input from "../../components/UI/Input";
import Button from "../../components/UI/Button";
import ReCAPTCHA from "react-google-recaptcha";
import {EmailValidation} from "../../utils/common";
import {LOADING_UI, UNLOADING_UI} from "../../redux/types";
import {publicRequest} from "../../services/api.service";

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE || ""
const ForgotPassword: React.FC<RouteComponentProps> = (props) => {
	const { register, handleSubmit, formState: { errors }, getValues } = useForm();
	const history  = useHistory();
	const dispatch = useDispatch();
	const recaptchaRef = useRef<ReCAPTCHA>(null);

	const user = useSelector((state: {user: InitialStateUserType}) => state.user)
	useEffect(()=>{
		if(user.authenticated){
			history.push('/dashboard')
		}
	},[user])
	const goBack = () =>{
		history.push('/auth/login');
	}
	const onSubmit = async (data: any) => {
		// await props.login(data);
		recaptchaRef.current?.execute()
	}
	const onVerifyCaptcha = async (token: string | null) => {
		if(token) {
			const values: any = getValues()
			dispatch({ type: LOADING_UI })
			try {
				await publicRequest("POST",'/auth/forgot-password',{
					email: values.email,
					recaptcha: token
				})
				toast.success(`Tautan sudah dikirim ke ${values.email}. Silahkan cek email anda untuk me-reset password anda`, {
					position: toast.POSITION.TOP_CENTER
				});
			}catch (e: Error | any) {
				toast.error(e?.message ?? 'Terjadi Kesalahan. Silahkan coba lagi beberapa saat', {
					position: toast.POSITION.TOP_CENTER
				});
			}
			dispatch({ type: UNLOADING_UI })
			console.log('captchaToken', token, values);
			recaptchaRef.current?.reset()
		}

	};
	return <>
		<div className={'main-content'}>
			<AuthHeader back={goBack} />
			<h1 className={'mb-5 fw-bolder'}>Forgot Password</h1>
			<p>Silahkan masukan email anda untuk me-reset password anda</p>
			<div className={'form-wrapper'}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="form-group mb-3">
						<Input
							placeholder='Email'
							name='email'
							register={register}
							errors={errors}
							block={true}
							rules={{
								required: "Email is required",
								validate:EmailValidation
							}}
							Icon={FaEnvelope}
						/>
						<ReCAPTCHA
							ref={recaptchaRef}
							size="invisible"
							sitekey={RECAPTCHA_SITE_KEY}
							onChange= {onVerifyCaptcha}
						/>
					</div>
					<div className="form-group mb-3">
						<Button primary block={true}>
							Submit
						</Button>
					</div>
				</form>
			</div>
		</div>
	</>
}

export default ForgotPassword
