import {
  LOADING_UI,
  UNLOADING_UI,
  SET_USER,
  SET_AUTHENTICATED,
  CLEAR_ERRORS,
  SET_ERRORS,
  SET_UNAUTHENTICATED,
} from '../types'
import { authenticatedRequest, publicRequest } from '../../services/api.service'
import { AxiosError } from 'axios'

export const login = (data: {
  email: string;
  password: string;
}) => async (dispatch: any) => {
  dispatch({ type: LOADING_UI })
  try{
    const resp = await publicRequest('POST','/auth/login',data)
    localStorage.setItem('_kmt_t',resp?.data?.token.token)
    localStorage.setItem('_kmt_u',JSON.stringify(resp?.data?.user))
    dispatch({ type: CLEAR_ERRORS });
    dispatch({
      type:SET_AUTHENTICATED
    })
    dispatch({
      type:SET_USER,
      payload:{
        profile:resp?.data?.user,
        token:resp?.data?.token.token
      }
    })

  }catch (e: Error | any) {
    dispatch({
      type: SET_ERRORS,
      payload: {
        error:true,
        message: e.message
      }
    });
  }
  dispatch({ type: UNLOADING_UI })
}

export const loginSocial = (data: any) => async (dispatch: any) => {
  dispatch({ type: LOADING_UI })
  try{
    const resp = await publicRequest('POST','/auth/login-social',data)
    localStorage.setItem('_kmt_t',resp?.data?.token.token)
    localStorage.setItem('_kmt_u',JSON.stringify(resp?.data?.user))
    dispatch({ type: CLEAR_ERRORS });
    dispatch({
      type:SET_AUTHENTICATED
    })
    dispatch({
      type:SET_USER,
      payload:{
        profile:resp?.data?.user,
        token:resp?.data?.token.token
      }
    })

  }catch (e: Error | any) {
    console.log(e);
    dispatch({
      type: SET_ERRORS,
      payload: {
        error:true,
        message: e.message
      }
    });
  }
  dispatch({ type: UNLOADING_UI })
}

export const signup = (data: {
  fullname: string;
  password: string;
  confirm_password: string;
  email: string;
}) => async (dispatch: any) => {
  dispatch({ type: LOADING_UI })
  try{
    const resp = await publicRequest('POST','/auth/sign-up',data)
    localStorage.setItem('_kmt_t',resp?.data?.token.token)
    localStorage.setItem('_kmt_u',JSON.stringify(resp?.data?.user))
    dispatch({ type: CLEAR_ERRORS });
    dispatch({
      type:SET_AUTHENTICATED
    })
    dispatch({
      type:SET_USER,
      payload:{
        profile:resp?.data?.user,
        token:resp?.data?.token.token
      }
    })

  }catch (e: Error | any) {
    console.log(e);
    dispatch({
      type: SET_ERRORS,
      payload: {
        error:true,
        message: e.message
      }
    });
  }
  dispatch({ type: UNLOADING_UI })
}

export const logout = () => async (dispatch: any) => {
  dispatch({ type: LOADING_UI })
  try{
    const resp = await authenticatedRequest('GET','/auth/logout')
    localStorage.removeItem('_kmt_t')
    localStorage.removeItem('_kmt_u')
    dispatch({ type: CLEAR_ERRORS });
    dispatch({
      type:SET_UNAUTHENTICATED
    })
    document.location.href = process.env.REACT_APP_WEB_URL || '/';

  }catch (e: Error | any) {
    dispatch({
      type: SET_ERRORS,
      payload: {
        error:true,
        message: 'Invalid User Detail'
      }
    });
  }
  dispatch({ type: UNLOADING_UI })
}
