import {Route, RouteComponentProps, Switch, useRouteMatch} from "react-router-dom";
import {AuthedRoutes} from "../components/AuthedRoutes";
import * as React from "react";
import Checkout from "../pages/payment/checkout";
import Wait from "../pages/payment/wait";
import Success from "../pages/payment/success";
import Failed from "../pages/payment/failed";

interface IMainContainerProps {
	component: any;
	path?: string;
	exact?: boolean;
}
const PaymentNonAuth: React.FC<IMainContainerProps> = (props) => {
	const { component: Component, ...rest } = props;
	return <Route {...rest} render={matchProps =>
		<>
			<div className={`main-content p-0`}>
				<Component {...matchProps} />
			</div>
		</>
	} />
}

export const paymentRoutes = (props: RouteComponentProps) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	let { path } = useRouteMatch();
	return (
		<>
			<Switch>
				<AuthedRoutes menu='course' path={`${path}/checkout/:id`}
				              exact={true} component={Checkout}
				              headerTitle='Pembayaran'
				              excludeBottom={true}
				              excludeHeader={true} noPadding={true}
				></AuthedRoutes>
				<AuthedRoutes menu='course' path={`${path}/wait/:uid`}
				              exact={true} component={Wait}
				              headerTitle='Menunggu Pembayaran'
				              excludeHeader={true}
				              excludeBottom={true} noPadding={true}
				></AuthedRoutes>
				<PaymentNonAuth path={`${path}/success/:uid`}
				              exact={true} component={Success}
				></PaymentNonAuth>
				<PaymentNonAuth path={`${path}/failed/:uid`}
				                exact={true} component={Failed}
				></PaymentNonAuth>
			</Switch>
		</>
	)
}
