import {ActivityType} from "../../../../typings/activity";
import VideoJS from "../../../../components/VideoJS";
import {VideoJsOptionType} from "../../../../typings/common";
import React, {useEffect, useRef, useState} from "react";

import './video.scss'
import {BEUrl, isYoutubeUrl} from "../../../../utils/common";
import {authenticatedRequestRaw} from "../../../../services/api.service";
import {VIDEO_TYPE_EXTERNAL} from "../../../../constant/activity";
interface IVideo {
	activity: ActivityType
}
const Video: React.FC<IVideo> = ({activity}) => {
	const playerRef = useRef(null);
	const [videoJsOptions, setVideoJsOptions] = useState<VideoJsOptionType>({
		autoplay: false,
		controls: true,
		responsive: false,
		fluid: false,
		sources: []
	})
	const [isDone, setIsDone] = useState<boolean>(!!activity?.memberActivities)
	useEffect(() => {
		if(activity){
			let src = `${BEUrl()}static/video/${activity.activity_url}`;
			let type = 'video/mp4';
			if(activity.activity_type === VIDEO_TYPE_EXTERNAL && activity.activity_url){
				src = activity.activity_url
				if(isYoutubeUrl(activity.activity_url)){
					type = 'video/youtube'
				}
			}
			setVideoJsOptions({...videoJsOptions, sources:[
					{ src, type }
				]})
		}
	},[activity])
	const handlePlayerReady = (player: any) => {
		playerRef.current = player;
		player.on('ended', () => {
			saveActivity()
		});
		player.on('dispose', () => {
			// videojs.log('player will dispose');
			// console.log('dispose')
		});
	};
	const saveActivity = async () => {
		if(!isDone){
			await authenticatedRequestRaw('POST','/course/activity/submit',{
				activity_id: activity?.id,
				course_id: activity?.course_id,
			})
			setIsDone(true);
		}
	}
  return <>
	  <div className="d-flex flex-column w-100">
		  <h2 className="fw-bold mb-3">{activity?.title}</h2>
		  <div className="w-100 mb-3" style={{
			  height:'300px'
		  }}>
			  <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
		  </div>
		  <div className={'border p-3 rounded-2 position-relative mb-3'}>
			  <div dangerouslySetInnerHTML={{ __html: activity?.description || '' }} />
		  </div>
    </div>
  </>
}
export default Video
