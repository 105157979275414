import React from 'react';
import { BrowserRouter as Routers, Route} from "react-router-dom";
import Home from '../pages/home'
import { authRoutes } from './auth'
import Dashboard from '../pages/dashboard/dashboard'
import { AuthedRoutes } from '../components/AuthedRoutes'
import { courseRoutes } from './course'
import { accountRoutes } from './account'
import { communicatorRoutes } from './communicator'
import { transactionRoutes } from './transaction'
import { paymentRoutes } from "./payment";
import {competencyTestRoutes} from "./competency-test";
export class Routes extends React.Component {
  render() {
    return (
      <Routers basename={'/'}>
        <Route exact path={"/"} component={Home} />
        <Route path={"/auth"} component={authRoutes} />
        <Route path={"/course"} component={courseRoutes} />
        <Route path={"/account"} component={accountRoutes} />
        <Route path={"/communicator"} component={communicatorRoutes} />
        <Route path={"/transaction"} component={transactionRoutes} />
        <Route path={"/payment"} component={paymentRoutes} />
        <Route path={"/competency"} component={competencyTestRoutes} />
        <AuthedRoutes menu='dashboard' path={"/dashboard"} component={Dashboard} excludeHeader={true}></AuthedRoutes>
      </Routers>
    )
  }
}
