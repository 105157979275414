import { RouteComponentProps } from 'react-router-dom'
import { InitialStateUIType, InitialStateUserType } from '../../typings/state'
import React, { useEffect, useState } from 'react'
import './course-list.scss'
import {connect, useDispatch} from 'react-redux'
import useQueryParams from '../../hooks/useQueryParams'
import InputSearch from '../../components/UI/InputSearch'
import CourseCard from '../../components/Layout/Course/CourseCard'
import CourseCategoryFilter from '../../components/Layout/Course/CourseCategoryFilter'
import {Course, CourseCategory} from "../../typings/course";
import {useQuery, useInfiniteQuery} from "react-query";
import {ApiResponse} from "../../typings/api";
import {authenticatedRequest, authenticatedRequestRaw} from "../../services/api.service";
import {LOADING_UI, UNLOADING_UI} from "../../redux/types";
import {useInView} from "react-intersection-observer";


interface CourseListProps extends RouteComponentProps{
  user: InitialStateUserType;
  UI: InitialStateUIType;
}
interface CourseListState{
  querySearch: string;
  sort: string;
  category: string;
}
const CourseList: React.FC<CourseListProps> = (props) => {
  const { ref, inView } = useInView()
  const dispatch = useDispatch();
  const [querySearch, setQuerySearch] = useState<string | null>(null)
  const [sort, setSort] = useState<string>('')
  const [category, setCategory] = useState<number | null | undefined>(null)
  const queryParams = useQueryParams();
  const [categories, setCategories] = useState<CourseCategory[]>([])
  const { data, fetchNextPage, isFetchingNextPage } = useInfiniteQuery<ApiResponse>(['course-list-all',category, querySearch], async ({ pageParam = 1 }) => {
    try{
      const params: any = {
        page: pageParam,
      }
      if(category){
        params['category'] = category;
      }
      if(querySearch && querySearch !== ''){
        params['search'] = querySearch
      }
      const response = await authenticatedRequest('POST','/course/list',params)
      return response as ApiResponse
    }catch (e) {
      return [] as unknown as ApiResponse
    }
  },{
    getPreviousPageParam: (firstPage: any) => firstPage?.meta?.previous_page_url ? Number(firstPage.meta.previous_page_url.replace('/?page=','')) : undefined,
    getNextPageParam: (firstPage: any) => firstPage?.meta?.next_page_url ? Number(firstPage.meta.next_page_url.replace('/?page=','')) : undefined,
    enabled: false
  })
  const { data: dataCategories, isLoading: loadingCategories } = useQuery('categories', async () => {
    const response = await authenticatedRequestRaw('GET','/course/categories')
    return response?.data?.data
  })
  const inputRef: React.RefObject<HTMLElement> = React.createRef();
  useEffect(() => {
    if(dataCategories){
      setCategories([{
        id:null,
        name: 'Semua'
      },...dataCategories])
    }
  },[dataCategories])
  useEffect(() => {
    if(querySearch && category){
      fetchNextPage();
    }
  },[querySearch, category])
  useEffect(() => {
    if(inView){
      fetchNextPage();
    }
  },[inView])
  useEffect(() => {
    if(loadingCategories || isFetchingNextPage){
      dispatch({ type: LOADING_UI })
    }else{
      dispatch({ type: UNLOADING_UI })
    }
  },[loadingCategories, isFetchingNextPage, dispatch])
  useEffect(() => {
    if(category!==undefined){
      setTimeout(()=>{
        fetchNextPage()
      },500)
    }
  },[category])
  useEffect(() => {
    setQuerySearch(queryParams.get('q'));
  }, [])
  const handleQuerySearch = (value: string) => {
    setQuerySearch(value);
  }
  const clearQuerySearch = () => {
    setQuerySearch('');
    setTimeout(()=>{
      fetchNextPage({
        pageParam: 1
      })
    },500)
  }
  const enterSearch = () => {
    inputRef?.current?.blur();
    fetchNextPage({
      pageParam: 1
    })
  }
  const setCategoryFilter = (catId: number | null) => {
    setCategory(catId)
  }

  return (
    <>
      <InputSearch
        placeholder={'Cari Kursus Disini'}
        onChange={handleQuerySearch.bind(this)}
        onEnter={enterSearch.bind(this)}
        defaultValue={querySearch}
        onClear={clearQuerySearch.bind(this)}
        ref={inputRef}
      />
      <CourseCategoryFilter categories={categories} category={category} onClick={(catId: number | null) => {
        setCategoryFilter(catId);
      }} />
      { data?.pages && (
        <>
          {data.pages[0]?.data?.length > 0 && (
            <>
              {data?.pages.map((l: any,idx1) => (
                <React.Fragment key={`st-more-pages-${idx1}`}>
                  {l.data.map((c:Course) => {
                    return (
                      <CourseCard key={c.id} {...c}></CourseCard>
                    )
                  })}
                </React.Fragment>
              ))}
              <span ref={ref} className={'edge-infinite'}></span>
            </>
          )}
        </>
        )
      }
    </>

  );
}
const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    UI: state.UI,
  };
};
export default connect(mapStateToProps,null)(CourseList);
