interface IAvatar{
  image: string
  variant?: 'circle' | 'rounded'
  size?: string
  customClass?: string
}
const Avatar = (props: IAvatar) => {
  let styles = {
    width: '3rem',
    height: '3rem',
  }
  let wrapperClass = 'avatar-wrapper ';
  let avatarClass = 'w-100 h-100 ';
  if(props.variant){
    avatarClass+= `rounded-${props.variant}`;
  }
  if(props.size){
    styles.width = props.size;
    styles.height = props.size;
  }
  if(props.customClass){
    wrapperClass+= props.customClass;
  }
  return (
    <>
      <div className={wrapperClass} style={styles}>
        <img src={props.image} className={avatarClass} alt='avatar'/>
      </div>
    </>
  )
}
export default Avatar;
