import {
  SET_USER,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
} from "../types";
import { ActionType, InitialStateUserType } from '../../typings/state'

const initialState: InitialStateUserType = {
  authenticated: false,
  token: '',
  profile: undefined
};

export default function (state = initialState, action: ActionType) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
      };
    case SET_UNAUTHENTICATED:
      return initialState;
    case SET_USER:
      return {
        authenticated: true,
        ...action.payload,
      };
    default:
      return state;
  }
}
