import React from "react";
import {ActivityType, MemberActivitiesType, MemberAnswerType} from "../../../../typings/activity";
import './feedback-quiz.scss'
import {Redirect, useHistory} from "react-router-dom";
import dayjs from "dayjs";
interface IQuiz {
	activity: ActivityType
}
const FeedbackQuiz: React.FC<IQuiz> = ({ activity}) => {
	const DisplayAnswer = (props: { memberAnswers : MemberAnswerType[] }) => {
		const questions = activity.questions;
		const memberAnswers = props.memberAnswers
		return <>
			{
				questions.map((q, index) => {
					const answer = memberAnswers[index]
					return <div className="d-flex w-100 flex-column mb-3 rounded-2 border position-relative" key={`q-${q.id}`}>
						<span className="position-absolute top-0 end-0 mt-3 me-3 fw-bold fs-7 bg-primary text-white py-1 px-2 rounded-2">{index + 1}</span>
						<div className="d-flex w-100 p-3 border-bottom bg-light"
						     dangerouslySetInnerHTML={{ __html: q?.text || '' }} />
						<div className="d-flex w-100 p-3">{ answer ?
							(
								answer.isAnswered ? `${answer.answer}${q.type === 'pg' ? `. ${q.options?.find(o => o.index === answer.answer)?.text}` : ''}`
								: '-'
							)
							: '-'
						}</div>
					</div>
				})
			}
		</>
	}
	if(activity?.memberActivities){
		const { score, feedback, scored_date, member_answer, created_at } = activity?.memberActivities
		const memberAnswer: MemberAnswerType[] = JSON.parse(member_answer as any)
		return <>
			<div className={'border py-3 position-relative d-flex justify-content-center w-100 rounded-top border-bottom-0'}>
				<div className="row w-100">
					<div className="col-6 border-end d-flex flex-column">
						<span className="fs-7 mb-1">Dikerjakan Tanggal</span>
						<span className={'fs-7 fw-bold'}>{dayjs(created_at).format('LLL')}</span>
						<div className={'my-2 w-100'}></div>
						<span className="fs-7 mb-1">Dinilai Tanggal</span>
						<span className={'fs-7 fw-bold'}>{scored_date ? dayjs(scored_date).format('LLL') : 'Menunggu Penilaian'}</span>
					</div>
					<div className="col-6 d-flex flex-column align-items-center justify-content-center">
						<span className={'fs-7 mb-2'}>Nilai Anda</span>
						<span className="score-title mb-2">{score ?? '-'}</span>
					</div>
				</div>
			</div>
			<div className="d-flex w-100 flex-column mb-3 rounded-bottom border">
				<div className="d-flex w-100 p-3 border-bottom bg-light fw-bold">Umpan Balik</div>
				<div className="d-flex w-100 p-3">{ feedback ?? '-' }</div>
			</div>
			<h3 className={'fw-bold text-start w-100 my-3'}>Jawaban</h3>
			<DisplayAnswer memberAnswers={memberAnswer} />
		</>
	}else{
		return <Redirect to={`/course/${activity?.course_id}`} />
	}
}
export default FeedbackQuiz
