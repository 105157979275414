import React from 'react'
import './course-rating-filter.scss';
import { CourseRating } from '../../../typings/course'
import { BsStarFill } from 'react-icons/bs'

interface CourseRatingFilterProps{
  ratings: CourseRating[]
  rating?: number | null
  onClick: Function

}
const CourseRatingFilter: React.FC<CourseRatingFilterProps> = (props) => {
  const { ratings, rating, onClick } = props;
  return (
    <div className='rating-filter-list my-2'>
      {
        ratings.map((rat,index)=>{
          return (
            <div
              className={`rating-filter-item ${rating === rat.value?'active':''}`}
              key={`rat-${index}`}
              onClick={()=>{
                onClick(rat.value);
              }}
            ><BsStarFill size={'0.75rem'} className='me-1 rating-filter-icon' /> {rat.label}</div>
          )
        })
      }
    </div>
  );
}
export default CourseRatingFilter;
