import React, {useEffect, useRef, useState} from 'react'
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom'
import { InitialStateUIType, InitialStateUserType } from '../../typings/state'
import {useQuery} from "react-query";
import { connect, useDispatch } from 'react-redux'
import './course-detail.scss'
import { LOADING_UI, UNLOADING_UI } from '../../redux/types'
import { CourseDetailTop } from './partials/course-detail-top'
import {BsCalendar} from 'react-icons/bs'
import { BiBook } from 'react-icons/bi'
import CourseMembers from '../../components/Layout/Course/CourseMembers'
import CourseRatings from '../../components/Layout/Course/CourseRatings'
import TabContentMaterial from './partials/tab-content-material'
import CourseDetailBottom from './partials/course-detail-bottom'
import {authenticatedRequestRaw} from "../../services/api.service";
import {CourseDetail as CourseDetailType} from "../../typings/course";
import TabContentAbout from "./partials/tab-content-about";
import dayjs from "dayjs";
import ReactStars from 'react-stars'
import {Modal} from "react-bootstrap";
import Button from "../../components/UI/Button";
import {toast} from 'react-toastify'


interface CourseDetailProps extends RouteComponentProps{
  user: InitialStateUserType;
  UI: InitialStateUIType;
}
const CourseDetail: React.FC<CourseDetailProps> = (props) => {
  const ratingDesc = useRef('')
  const history  = useHistory();
  const dispatch = useDispatch();
  const params:any  = useParams();
  const [courseId, setCourseId] = useState<number | null>(null);
  const [course, setCourse] = useState<CourseDetailType | null>(null);
  const [tabActive, setTabActive] = useState<'live-session' | 'about' | 'activity'>('about');
  const [showModalRating, setShowModalRating] = useState<boolean>(false)
  const [rating, setRating] = useState<number>(0)
  const [errorValidationRating, setErrorValidationRating] = useState<boolean>(false)
  const { data, refetch, isFetching } = useQuery('courseDetail', async () => {
    const response = await authenticatedRequestRaw('GET','/course/' + courseId)
    return response?.data?.data
  }, {enabled: false})
  useEffect(() => {
    if(isNaN(params?.id)){
      history.push('/dashboard')
    }
    setCourseId(Number(params?.id));
  },[]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if(courseId){
      refetch();
    }
  },[courseId]);
  useEffect(() => {
    if(data){
      setCourse(data)
    }
  },[data]);
  useEffect(() => {
    if(course){
      if(course.isCompleted && course?.review === null){
        setShowModalRating(true)
      }
    }
  },[course]);
  useEffect(() => {
    if(isFetching){
      dispatch({ type: LOADING_UI })
    }else{
      dispatch({ type: UNLOADING_UI })
    }
  },[isFetching, dispatch])
  useEffect(() => {
    if(course?.isJoined){
      setTabActive('activity')
    }
  },[course?.isJoined]);
  const onSetTabActive = (tabs: 'live-session' | 'about' | 'activity') => {
    setTabActive(tabs);
  }
  const isTabActive = (tabs: 'live-session' | 'about' | 'activity') => {
    return tabActive === tabs ? 'active':'';
  }
  const isTabContentActive = (tabs: 'live-session' | 'about' | 'activity') => {
    return tabActive === tabs ? 'show active':'';
  }
  const getDurationDays = () => {
    if(course){
      const start = dayjs(course.start_date);
      const end = dayjs(course.end_date);
      return end.diff(start, 'days')+' Hari'
    }
    return ''
  }
  const submitRating = async () => {
    if(rating > 0){
      setShowModalRating(false)
      dispatch({ type: LOADING_UI })
      await authenticatedRequestRaw('POST','/course/submitRating',{
        course_id: course?.id,
        rate: rating,
        description: ratingDesc.current,
      })
      dispatch({ type: UNLOADING_UI })
      toast.success("Berhasil mengirim ulasan", {
        position: toast.POSITION.TOP_CENTER
      });
      refetch();
    }else{
      setErrorValidationRating(true);
    }
  }
  const getRating = (rating: number): number => {
    if(course?.totalReviews){
      return Number((rating / course?.totalReviews).toFixed(1))
    }
    return 0
  }
  return (
    <>
      <div className={'cd-container cd-with-bottom'}>
        <CourseDetailTop
          courseImage={course?.logo}
          courseName={course?.name}
          organization={course?.organization?.name}
        />
        <div className='cd-content'>
          <h1 className='fw-bold mb-2'>{course?.name}</h1>
          {course && <span className={'fs-7 text-muted mb-3'}>{`${dayjs(data.start_date).format('LL')} - ${dayjs(data.end_date).format('LL')}`}</span>}
          <div className='mb-4 d-flex justify-content-between'>
            <CourseMembers members={course?.courseMembers || []} totalMembers={course?.courseMembers?.length || 0} />
            {courseId && <CourseRatings rating={getRating(course?.rating || 0)} totalReviews={course?.totalReviews || 0} courseId={courseId}/>}
          </div>
          <div className='d-flex w-100 align-items-center justify-content-between cd-content-info pb-2 mb-2 border-bottom'>
            <span className="badge course-category-label me-3">{course?.category?.name}</span>
            <span className='me-3 d-flex align-items-center'>
              <BiBook size='0.875rem' className='me-1'/>{course?.totalActivities} Materi
            </span>
            <span className='me-3 d-flex align-items-center'>
              <BsCalendar size='0.875rem' className='me-1'/> {getDurationDays()}
            </span>
          </div>
          <ul className="nav border-bottom cd-nav">
            <li className={`nav-item ${isTabActive('about')}`} onClick={onSetTabActive.bind(this,'about')}>
              <a className="nav-link">Tentang</a>
            </li>
            <li className={`nav-item ${isTabActive('activity')}`} onClick={onSetTabActive.bind(this,'activity')}>
              <a className="nav-link">Materi</a>
            </li>
            <li className={`nav-item ${isTabActive('live-session')}`} onClick={onSetTabActive.bind(this,'live-session')}>
              <a className="nav-link">Sesi Langsung</a>
            </li>
          </ul>
          <div className="tab-content cd-tab-content">
            <div className={`tab-pane fade ${isTabContentActive('about')}`} role="tabpanel">
              {course && <TabContentAbout course={course} />}
            </div>
            <div className={`tab-pane fade ${isTabContentActive('activity')}`} role="tabpanel">
              {course && <TabContentMaterial activities={course.activities} course={course} />}
            </div>
            <div className={`tab-pane fade ${isTabContentActive('live-session')}`} role="tabpanel">
              Belum Ada
            </div>
          </div>
        </div>
      </div>
      { course && course?.isJoined === false && <CourseDetailBottom course={course} />}
      <Modal show={showModalRating} onHide={()=>{ setShowModalRating(false) }} centered={true}>
        <Modal.Header >
          <Modal.Title>Ulasan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column">
            <p>Anda telah menyelesaikan kursus ini. Silahkan memberikan rating pada kursus ini</p>
            <div className="w-100">
              <ReactStars
                value={rating}
                count={5}
                onChange={(rate) => {
                  setRating(rate)
                }}
                size={48}
                color2={'#ffd700'}
                half={false}
              />
            </div>
            { errorValidationRating && (<p className="text-danger fs-7 fst-italic mt-0">Rating tidak boleh kosong</p>)}
            <textarea className="form-control" placeholder="Tulis ulasan...." rows={5} onChange={(event)=>ratingDesc.current = event.target.value}></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button secondary onClick={()=>{ setShowModalRating(false) }}>
            Nanti Saja
          </Button>
          <Button primary className={'min-w-75px'} onClick={submitRating}>
            Kirim
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    UI: state.UI,
  };
};
export default connect(mapStateToProps,null)(CourseDetail);
