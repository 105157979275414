import './tab-content-about.scss'
import {CourseDetail} from "../../../typings/course";
import {getYoutubeID} from "../../../utils/common";
interface ITabContentAbout{
	course: CourseDetail
}
const TabContentAbout = ({course}: ITabContentAbout) => {
	let youtubeUrl: string | null = null;
	let haveFacilitator = true;
	if(course?.summary_video){
		youtubeUrl = `https://www.youtube.com/embed/${getYoutubeID(course?.summary_video)}`;
	}
	if(course?.facilitator_name || course?.facilitator_img){
		haveFacilitator = true;
	}
	return (
		<>
			<div className={'d-flex flex-column'}>
				{
					haveFacilitator && (
						<>
							<span className="fw-bolder mb-2">Fasilitator</span>
							<div className="d-flex align-items-center mb-3 facilitator">
								<img src={course?.facilitator_img ?? 'https://placehold.co/100x100'} />
								<div className="d-flex flex-column w-75">
									<span className="fw-bolder">{ course?.facilitator_name ?? '-' }</span>
									<span className="text-dark text-opacity-75 fs-7">{ course?.facilitator_desc ?? '-' }</span>
								</div>
							</div>
						</>
					)
				}
				<div className="d-flex flex-column">
					<span className="mb-2 fw-bolder">Tentang</span>
					<p className="text-dark text-opacity-75">{course.description}</p>
					{
						youtubeUrl && (
							<iframe style={{ width: '100%', height: '240px' }}
							        src={youtubeUrl}
							        title="YouTube video player" frameBorder="0"
							        className="mt--20"
							        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							        allowFullScreen>
							</iframe>
						)
					}
				</div>
				{/*<span className={'mb-2 fs-7 text-muted'}>Lembaga</span>*/}
				{/*<h4 className={'fw-bold'}>{course.organization.name}</h4>*/}
				{/*<p className={'mb-3'}>{course.organization.description}</p>*/}
				{/*<h3 className={'fw-bold'}>Tentang Kursus</h3>*/}
				{/*<p>{course.description}</p>*/}
			</div>
		</>
	)
}
export default TabContentAbout
