import * as React from 'react'
import { Route, RouteComponentProps, Switch, useRouteMatch } from 'react-router-dom'
import Login from '../pages/auth/login'
import Signup from '../pages/auth/signup'
import ForgotPassword from "../pages/auth/forgot-password";
import ResetPassword from "../pages/auth/reset-password";
export const authRoutes = (props: RouteComponentProps) =>{
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let { path } = useRouteMatch();
  return (
    <>
    <Switch>
      <Route
        path={`${path}/login`}
        component={Login}
      />
      <Route
        path={`${path}/sign-up`}
        component={Signup}
      />
      <Route
        path={`${path}/forgot-password`}
        component={ForgotPassword}
      />
      <Route
        path={`${path}/reset-password/:token`}
        component={ResetPassword}
      />
    </Switch>
    </>
  )
}

