import { CourseCategory, CourseRating } from '../typings/course'

export const categories: CourseCategory[] = [
  {
    id: null,
    name: 'Semua'
  },
  {
    id: 1,
    name: 'Matematika'
  },
  {
    id: 2,
    name: '3D Class'
  },
  {
    id: 3,
    name: 'Marketing'
  },
  {
    id: 4,
    name: 'Fotografi'
  },
]

export const ratingsList: CourseRating[] = [
  {
    value: null,
    label: 'Semua'
  },
  {
    value: 1,
    label: '1'
  },
  {
    value: 2,
    label: '2'
  },
  {
    value: 3,
    label: '3'
  },
  {
    value: 4,
    label: '4'
  },
  {
    value: 5,
    label: '5'
  },
]
