import './payment-finish.scss'
import {useHistory, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {PaymentFinish} from "../../typings/payment";
import {useQuery} from "react-query";
import {authenticatedRequestRaw} from "../../services/api.service";
import {AxiosError} from "axios";
import {LOADING_UI, UNLOADING_UI} from "../../redux/types";
import {
	PAYMENT_CANCELED,
	PAYMENT_EXPIRED,
	PAYMENT_FAILED,
	PAYMENT_SUCCESS,
	PM_CC,
	PM_GOPAY,
	PM_QRIS,
	PM_SHOPEE,
	PM_VA_BCA,
	PM_VA_BNI,
	PM_VA_BRI
} from "../../constant/payment";
import gopayIcon from "../../assets/images/logo/gopay.png";
import shopeeIcon from "../../assets/images/logo/shopeepay.png";
import qrisIcon from "../../assets/images/logo/qris.png";
import ccIcon from "../../assets/images/logo/cc.png";
import bcaIcon from "../../assets/images/logo/bca.png";
import briIcon from "../../assets/images/logo/bri.png";
import bniIcon from "../../assets/images/logo/bni.png";
import {BsFillXCircleFill} from "react-icons/bs";
import Button from "../../components/UI/Button";
import {MobileView} from "react-device-detect";

const Failed = () => {
	const history  = useHistory();
	const dispatch = useDispatch();
	const params:any  = useParams();
	const [uid, setUid] = useState<string | null>(null);
	const [payment, setPayment] = useState<PaymentFinish | null>(null);
	const { data, refetch, isFetching } = useQuery<PaymentFinish | undefined>(['payment-finish-failed',uid], async () => {
		if(uid){
			try {
				const response = await authenticatedRequestRaw('GET', '/payment/finish/' + uid)
				return response?.data?.data as PaymentFinish
			}catch (error: AxiosError | any) {
				history.push('/')
			}
		}
		return undefined
	}, {enabled: false})
	useEffect(() => {
		if(isFetching){
			dispatch({ type: LOADING_UI })
		}else{
			dispatch({ type: UNLOADING_UI })
		}
	},[isFetching, dispatch])
	useEffect(() => {
		if(params?.uid){
			setUid(params?.uid);
		}
	},[params?.uid]) // eslint-disable-line react-hooks/exhaustive-deps
	useEffect(() => {
		if(uid){
			refetch().then(res => {
				dispatch({ type: UNLOADING_UI })
				if(res?.data){
					if(![PAYMENT_FAILED, PAYMENT_EXPIRED, PAYMENT_CANCELED].includes(res?.data?.status || 0)){
						history.push('/')
					}else{
						setPayment(res?.data)
					}
				}
			})
		}
	},[uid])
	const PaymentMethod = () => {
		if(payment){
			let data = {
				label: '',
				icon: ''
			}
			switch (payment.payment_method) {
				case PM_GOPAY:
					data.icon = gopayIcon;
					data.label = 'Gopay';
					break;
				case PM_SHOPEE:
					data.icon = shopeeIcon;
					data.label = 'ShopeePay';
					break;
				case PM_QRIS:
					data.icon = qrisIcon;
					data.label = 'QRIS';
					break;
				case PM_CC:
					data.icon = ccIcon;
					data.label = 'Kartu Kredit';
					break;
				case PM_VA_BCA:
					data.icon = bcaIcon;
					data.label = 'BCA Virtual Account';
					break;
				case PM_VA_BRI:
					data.icon = briIcon;
					data.label = 'BRI Virtual Account';
					break;
				case PM_VA_BNI:
					data.icon = bniIcon;
					data.label = 'BNI Virtual Account';
					break;
			}
			return <>
				<div className={'d-flex justify-content-between align-items-center'}>
					<span>Metode Pembayaran</span>
					<div className={'d-flex'} style={{gap:'10px'}}>
						<img src={data.icon} style={{width:'1.5rem'}}/>
						<span className={'fw-bold'}>{data.label}</span>
					</div>
				</div>
			</>
		}
		return null
	}
	const goBack = () => {
		history.push('/course')
	}
	return <>
		<div className={'header-finish failed mb-5'}>
			Pembayaran Gagal
		</div>
		<div className={'payment-container'}>
			<div className={'d-flex flex-column align-items-center mb-5'}>
				<div className={'mb-5'}>
					<BsFillXCircleFill size={'10rem'} className={'icon-payment failed'}/>
				</div>
				<p className={'text-center fw-bold mb-2'}>Maaf, pembayaran anda gagal!</p>
				<p className={'fs-7 w-75 text-center'}>Silahkan mencoba kembali</p>
			</div>
			<h5 className={'text-muted fw-bold mb-3'}>Detail</h5>
			<div className={'border border-bottom w-100 mb-3'}></div>
			<div className={'d-flex flex-column w-100 mb-4'}>
				<div className={'d-flex justify-content-between mb-3'}>
					<span>No. Transaksi</span>
					<span className={'fw-bold'}>#{payment?.uid}</span>
				</div>
				<div className={'d-flex justify-content-between mb-3'}>
					<span>Kursus</span>
					<span className={'fw-bold'}>{payment?.course_name}</span>
				</div>
				<div className={'d-flex justify-content-between mb-3'}>
					<span>Total</span>
					<span className={'fw-bold'}>Rp{payment?.final_amount?.toLocaleString('id-ID')}</span>
				</div>
				<PaymentMethod />
			</div>
			<div className={'d-flex justify-content-center'} style={{gap:'10px'}}>
				<Button outlinePrimary rounded onClick={goBack}>Kembali</Button>
			</div>
		</div>
	</>
}

export default Failed
