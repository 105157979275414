import './course-detail-top.scss'
import { useHistory } from 'react-router-dom'
import React from 'react'
import { FaChevronLeft } from 'react-icons/fa'
interface ICourseDetailTop{
  courseName?: string;
  courseImage?: string;
  organization?: string;
}
export const CourseDetailTop = (props: ICourseDetailTop) => {
  const history = useHistory();
  const goBack = () => history.push('/course');

  return (<div className='cd-top'>
    <div
      className='cd-image'
      style={props?.courseImage?{
        backgroundImage: `url(${props?.courseImage})`
      }:{}}
    ></div>
    <a className='cd-back' onClick={goBack.bind(this)}>
      <FaChevronLeft />
    </a>
    <div className='cd-top-content'></div>
  </div>)
}
