import React, {useEffect} from "react";
import {RouteComponentProps, useHistory, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {InitialStateUserType} from "../../typings/state";
import AuthHeader from "../../components/Layout/Auth/Header";
import Input from "../../components/UI/Input";
import {PasswordValidation} from "../../utils/common";
import {FaKey} from "react-icons/fa";
import Button from "../../components/UI/Button";
import {LOADING_UI, UNLOADING_UI} from "../../redux/types";
import {publicRequest} from "../../services/api.service";
import {toast} from "react-toastify";

const ResetPassword: React.FC<RouteComponentProps> = (props) => {
	const { register, handleSubmit, formState: { errors }, getValues } = useForm();
	const history  = useHistory();
	const dispatch = useDispatch();
	const params:any  = useParams();
	const user = useSelector((state: {user: InitialStateUserType}) => state.user)
	useEffect(()=>{
		if(user.authenticated){
			history.push('/dashboard')
		}
	},[user])
	const goBack = () =>{
		history.push('/auth/login');
	}
	const onSubmit = async (data: any) => {
		if(params?.token) {
			dispatch({ type: LOADING_UI })
			try {
				await publicRequest("POST",'/auth/set-password',{
					password: data.password,
					token: params?.token
				})
				toast.success(`Password berhasil diperbaharui. Silahkan melakukan login kembali`, {
					position: toast.POSITION.TOP_CENTER
				});
				dispatch({ type: UNLOADING_UI })
				history.push('/auth/login')
			}catch (e: Error | any) {
				dispatch({ type: UNLOADING_UI })
				toast.error(e?.message ?? 'Terjadi Kesalahan. Silahkan coba lagi beberapa saat', {
					position: toast.POSITION.TOP_CENTER
				});
			}
		}
	}
	return <>
		<div className={'main-content'}>
			<AuthHeader back={goBack} />
			<h1 className={'mb-5 fw-bolder'}>Reset Password</h1>
			<p>Silahkan masukan password baru anda</p>
			<div className={'form-wrapper'}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="form-group mb-3">
						<Input
							placeholder='Password'
							name='password'
							type='password'
							register={register}
							errors={errors}
							block={true}
							rules={{
								required: "Password is required",
								minLength: {
									value: 8,
									message: "minimum length 8"
								},
								validate:PasswordValidation
							}}
							Icon={FaKey}
						/>
					</div>
					<div className="form-group mb-3">
						<Input
							placeholder='Confirm Your Password'
							name='password_confirmation'
							type='password'
							register={register}
							errors={errors}
							block={true}
							rules={{
								required: "Confirm Password is required",
								validate: (value) => {
									const { password } = getValues();
									return password === value || "Passwords should match!";
								},
								minLength: {
									value: 8,
									message: "minimum length 8"
								}
							}}
							Icon={FaKey}
						/>
					</div>
					<div className="form-group mb-3">
						<Button primary block={true}>
							Submit
						</Button>
					</div>
				</form>
			</div>
		</div>
	</>
}

export default ResetPassword
