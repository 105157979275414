import * as React from 'react'
import { RouteComponentProps, Switch, useRouteMatch } from 'react-router-dom'
import CourseList from '../pages/course/course-list'
import { AuthedRoutes } from '../components/AuthedRoutes'
import CourseDetail from '../pages/course/course-detail'
import Activity from "../pages/course/activity/activity";
import CourseReviews from "../pages/course/course-reviews";
export const courseRoutes = (props: RouteComponentProps) =>{
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <AuthedRoutes menu='course' path={`${path}/`}
                      exact={true} component={CourseList}
                      headerTitle='Daftar Kursus'
        ></AuthedRoutes>
        <AuthedRoutes menu='course' path={`${path}/activity/:id`}
                      exact={true} component={Activity}
                      excludeHeader={true}
                      excludeBottom={true}
                      noPadding
                      headerTitle='Aktifitas'
        ></AuthedRoutes>
        <AuthedRoutes menu='course' path={`${path}/activity/:id`}
                      exact={true} component={Activity}
                      excludeHeader={true}
                      excludeBottom={true}
                      noPadding
                      headerTitle='Aktifitas'
        ></AuthedRoutes>
        <AuthedRoutes menu='course' path={`${path}/reviews/:id`}
                      exact={true} component={CourseReviews}
                      excludeHeader={true}
                      excludeBottom={true}
                      noPadding
                      headerTitle='Aktifitas'
        ></AuthedRoutes>
        <AuthedRoutes menu='course' path={`${path}/:id`}
                      exact={true} component={CourseDetail}
                      excludeHeader={true}
                      excludeBottom={true}
                      noPadding={true}
        ></AuthedRoutes>
      </Switch>
    </>
  )
}

