import './course-review.scss';
import Avatar from '../../UI/Avatar'
import { BsStarFill, BsHandThumbsUpFill, BsHandThumbsUp } from 'react-icons/bs'
import {CourseReview as CourseReviewType} from "../../../typings/course";
const CourseReview = (props: CourseReviewType) => {
  const {avatar, name, rate, description, createdAt} = props
  return (
    <>
      <div className='d-flex flex-column review-item mb-4 gap-2'>
        <div className='d-flex justify-content-between align-items-center review-item-header'>
          <div className='d-flex align-items-center review-item-info me-3'>
            <Avatar image={avatar || 'https://placehold.co/60'} customClass='me-3 border rounded-circle' variant='circle' size='2.5rem' />
            <span className='fw-bold'>{name}</span>
          </div>
          <div className='review-item-action justify-content-end'>
            <div className='review-item-rating-label'>
              <BsStarFill className='me-2 fill-primary'/>
              <span className='fw-bold'>{rate}</span>
            </div>
          </div>
        </div>
        <div className='review-item-desc w-100'>
          {description}
        </div>
        <div className='d-flex align-items-center review-item-footer'>
          <span className='fs-7'>3 menit yang lalu</span>
        </div>
      </div>
    </>
  )
}
export default CourseReview;
