import {ActivityQuestions, ActivityType, MemberAnswerType} from "../../typings/activity";
import React, {useEffect, useRef, useState} from "react";
import './../../pages/course/activity/partials/quiz.scss'
import Button from "../../components/UI/Button";
import Pagination from 'react-bootstrap/Pagination';
import {Modal} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {LOADING_UI, UNLOADING_UI} from "../../redux/types";
import {authenticatedRequestRaw} from "../../services/api.service";
import {toast} from 'react-toastify'
import {CompetencyTestDetailType} from "../../typings/competency-test";
interface IQuiz {
	activity: CompetencyTestDetailType
	timeOver: boolean
}
const Quiz: React.FC<IQuiz> = (props) => {
	const history  = useHistory();
	const dispatch = useDispatch();
	const answerText = useRef("");
	const { activity } = props
	const questionsLength = activity.questions.length;
	let memberAnswerDefault: any[] = activity.questions.map((q) => {
		return {
			id: q.id,
			isAnswered: 0,
			answer:'',
			score: q.score
		}
	})
	const [question, setQuestion] = useState<ActivityQuestions>()
	const [questionIndex, setQuestionIndex] = useState<number>(0)
	const [disablePrev, setDisablePrev] = useState<boolean>(false)
	const [disableNext, setDisableNext] = useState<boolean>(false)
	const [disablePagePrev, setDisablePagePrev] = useState<boolean>(false)
	const [disablePageNext, setDisablePageNext] = useState<boolean>(false)
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [memberAnswers, setMemberAnswers] = useState<MemberAnswerType[]>(memberAnswerDefault)
	const [modalFinish, showModalFinish] = useState(false)
	useEffect(() => {
		setQuestion(activity.questions[questionIndex])
		if(activity.questions[questionIndex].type === 'essay'){
			answerText.current = memberAnswers[questionIndex].answer || ''
		}

		setDisablePagePrev(questionIndex === 0)
		setDisablePageNext(questionIndex === (questionsLength - 1))
	},[questionIndex])
	useEffect(()=>{
		if(props.timeOver){
			submitQuiz();
		}
	},[props.timeOver])
	const displayForm = () => {
		if(question){
			switch (question.type) {
				case "image":
				case "pg":
					return <DisplayOptions />
				case "essay":
					return <DisplayInput />
			}
		}
	}
	const DisplayOptions = () => {
		if(question){
			const memberAnswer = memberAnswers[questionIndex];
			return <div className={'options-wrapper d-flex flex-column w-100'}>
				{
					question.options?.map((opt, idx) => (
						<div className={`options-item d-flex w-100 ${memberAnswer.isAnswered && memberAnswer.answer === opt.index ? 'selected' : ''}`}
						     onClick={()=>handleAnswer(opt.index)} key={`opt-${questionIndex}-${idx}`}
						>
							<div className={'opt-index'}>{opt.index}</div>
							{
								question?.type === 'pg' ? <div className={'opt-text'}>{opt.text}</div> :
									<>
										<img src={opt.text} style={{ maxWidth: '200px', border: '1px solid #d9d9d9', borderRadius: '10px', padding: '8px' }} />
									</>
							}
						</div>
					))
				}
			</div>
		}
		return null
	}

	const handleAnswer = (index: string) => {
		setMemberAnswers(memberAnswers.map((a,idx) => {
			return {...a, ...( questionIndex === idx ? { isAnswered: 1, answer: index }:{} )}
		}))
	}

	const handleChangeText = (e: any) => {
		answerText.current = e.target.value
	}
	const DisplayInput = () => {
		if(question) {
			return <div className={'question-input'}>
				<textarea defaultValue={memberAnswers[questionIndex].answer}  rows={3} placeholder={'Masukan jawaban...'} onChange={handleChangeText.bind(this)}></textarea>
			</div>
		}
		return null
	}

	const Navigation = () => {
		const perPage = 5
		const qLength = activity.questions.length;
		const totalPaginationPage = Math.ceil(qLength/perPage);
		const offset = (currentPage-1)*perPage;
		let maxPage = currentPage*perPage;
		if(currentPage === totalPaginationPage){
			maxPage = (qLength - (maxPage-perPage)) + ((currentPage-1)*perPage);
		}
		const pages = Array.from(
			{ length: maxPage - offset },
			(_, i) => offset + 1 + i
		)
		// @ts-ignore
		handlePrevNex(pages,qLength)
		return <div className={'quiz-navigation d-flex flex-column w-100 align-items-center p-3'}>
			<div className={'quiz-pagination mb-3 w-100 justify-content-center d-flex'}>
				<Pagination>
					<Pagination.Prev disabled={disablePrev} onClick={onPrev}/>
					{
						pages.map(p => (
							<Pagination.Item onClick={()=>handlePage(p)} active={questionIndex === (p-1)} >{p}</Pagination.Item>
						))
					}
					<Pagination.Next disabled={disableNext} onClick={onNext}/>
				</Pagination>
			</div>
			<Button primary block onClick={confirmQuiz}>
				Kirim
			</Button>
		</div>
	}

	const handlePrevNex = (pages: number[], qLength: number, ) => {
		if(Math.max(...pages) >= qLength){
			setDisableNext(true)
		}else{
			setDisableNext(false)
		}
		if(currentPage===1){
			setDisablePrev(true)
		}else{
			setDisablePrev(false)
		}
	}
	const onNext = () => {
		if(!disableNext){
			handleEssayAnswer();
			setCurrentPage(currentPage+1)
		}
	}
	const onPrev = () => {
		if(!disablePrev){
			handleEssayAnswer();
			setCurrentPage(currentPage-1)
		}
	}
	const onPagePrev = () => {
		if(!disablePagePrev){
			handleEssayAnswer()
			setQuestionIndex(questionIndex - 1);
		}
	}
	const onPageNext = () => {
		if(!disablePageNext){
			handleEssayAnswer()
			setQuestionIndex(questionIndex + 1);
		}
	}
	const handlePage = (page: number) => {
		handleEssayAnswer();
		setQuestionIndex(page - 1);

	}
	const handleEssayAnswer = () => {
		if(question?.type === 'essay'){
			const memberAnswerIndex = memberAnswers.findIndex(a => a.id === question.id)
			const memberAnswerClone = [...memberAnswers];
			memberAnswerClone[memberAnswerIndex].isAnswered = answerText.current !== '' ? 1 : 0;
			memberAnswerClone[memberAnswerIndex].answer = answerText.current;
			setMemberAnswers([...memberAnswerClone])
			answerText.current = ''
		}
	}

	const confirmQuiz = () => {
		handleEssayAnswer();
		showModalFinish(true)
	}

	const submitQuiz = async () => {
		showModalFinish(false)
		dispatch({ type: LOADING_UI })
		await authenticatedRequestRaw('POST','/competency-test/submit',{
			test_id: activity?.id,
			answers: memberAnswers,
		})
		dispatch({ type: UNLOADING_UI })
		history.push(`/competency/${activity?.id}`)
		toast.success("Berhasil mengirim data tes", {
			position: toast.POSITION.TOP_CENTER
		});
	}

	return <>
		<div className={'quiz-container d-flex flex-column w-100'}>
			<div className={'border p-3 rounded-2 position-relative fw-bold text-center mb-2'}>
				{question?.section}
			</div>
			<div className={'border p-3 rounded-2 position-relative pt-5'}>
				<span className={'fs-7 position-absolute top-0 end-0 mt-3 me-3 fw-bold'}>Pertanyaan {questionIndex + 1}</span>
				<div dangerouslySetInnerHTML={{ __html: question?.text || '' }} />
			</div>
			<div className={'position-relative pt-4 mb-3'}>
				{
					question ? <div className={'question-answer-wrapper'}>
						{displayForm()}
					</div> : null
				}
			</div>
			<div className={'d-flex w-100 justify-content-center'}>
				<Button primary className={'me-1'} disabled={disablePagePrev} onClick={onPagePrev}>Sebelumnya</Button>
				<Button primary className={'ms-1'} disabled={disablePageNext} onClick={onPageNext}>Selanjutnya</Button>
			</div>
			<Navigation />
		</div>
		<Modal show={modalFinish} onHide={()=>{ showModalFinish(false) }} centered={true}>
			<Modal.Body>Anda yakin akan menyelesaikan tes ini?</Modal.Body>
			<Modal.Footer>
				<Button secondary onClick={()=>{ showModalFinish(false) }}>
					Cek kembali
				</Button>
				<Button primary className={'min-w-75px'} onClick={submitQuiz}>
					Ya
				</Button>
			</Modal.Footer>
		</Modal>
	</>
}

export default Quiz
