import * as React from "react";
import {
  Route,
  RouteComponentProps,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import { AuthedRoutes } from "../components/AuthedRoutes";
import Communicator from '../pages/communicator/communicator'
export const communicatorRoutes = (props: RouteComponentProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <AuthedRoutes
          menu="communicator"
          path={`${path}/`}
          exact={true}
          component={Communicator}
          headerTitle="Komunikator"
        ></AuthedRoutes>
      </Switch>
    </>
  );
};
