import {RouteComponentProps} from "react-router-dom";
import {InitialStateUIType, InitialStateUserType} from "../../typings/state";
import React, {useEffect, useMemo, useState} from "react";
import {connect, useDispatch} from "react-redux";
import { Controller, useForm } from 'react-hook-form'
import Input from '../../components/UI/Input'
import Button from '../../components/UI/Button'
import {FaTimes, FaUser} from "react-icons/fa";
import {useQuery} from "react-query";
import {authenticatedRequestRaw} from "../../services/api.service";
import {LOADING_UI, UNLOADING_UI} from "../../redux/types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Options} from "../../typings/common";
import Select from "../../components/UI/Select";
import './profile-edit.scss'
import {BiEdit, BiEditAlt} from "react-icons/bi";
import axios from "axios";
import {getToken} from "../../utils/common";
import dayjs from "dayjs";
import {toast} from 'react-toastify'
const genderOptions: Options[] = [
	{
		value: 'M',
		label: 'Laki - laki',
	},
	{
		value: 'F',
		label: 'Perempuan',
	},
]
interface ProfileEditProps extends RouteComponentProps{
	user: InitialStateUserType;
	UI: InitialStateUIType;
}

const ProfileEdit: React.FC<ProfileEditProps> = (props) => {
	const dispatch = useDispatch();
	const { data: profile, isLoading} = useQuery('profileDetail', async () => {
		const response = await authenticatedRequestRaw('GET','/profile/detail')
		let data = response?.data?.data;
		if( data ){
			data = {...data, dob: data.dob? new Date(data.dob) : null}
			console.log(data,'ewe');
		}
		return response?.data?.data
	})
	const [avatarFile, setAvatarFile] = useState(null);
	const [avatar, setAvatar] = useState(null);
	useEffect(() => {
		dispatch({ type: isLoading? LOADING_UI : UNLOADING_UI })
	},[isLoading, dispatch])
	useEffect(() => {
		if(profile){
			reset({...profile, dob: profile.dob? new Date(profile.dob) : null})
			if(profile?.avatar){
				setAvatar(profile?.avatar)
			}
		}
	},[profile])
	useEffect(() => {
		if(avatarFile){
			// @ts-ignore
			setAvatar(URL.createObjectURL(avatarFile))
		}
	},[avatarFile])
	const { control, register, handleSubmit, formState: { errors }, getValues, reset } = useForm();
	const onSubmit = async (data: any) => {
		dispatch({ type: LOADING_UI })
		const formData = new FormData();
		formData.append('fullname',data.fullname)
		formData.append('dob',data?.dob ? dayjs(data.dob).format('YYYY-MM-DD') : '')
		formData.append('gender',data.gender || '')
		formData.append('phone',data.phone || '')
		if(avatarFile){
			formData.append('avatar', avatarFile)
		}
		axios({
			url: process.env.REACT_APP_API_URL + '/profile/save',
			method: 'post',
			data: formData,
			headers: {
				"Content-Type": "multipart/form-data",
				"Authorization": `Bearer ${getToken()}`,
			},
			onUploadProgress: (event) => {
				if(event.total){
					const percentCompleted = Math.round((100 * event.loaded) / (event?.total || 1));
				}
			},
		}).then(response => response.data).catch((err) => {
			throw err.response;
		}).finally(() => {
			dispatch({ type: UNLOADING_UI })
			toast.success("Sunting Profil Berhasil!", {
				position: toast.POSITION.TOP_CENTER
			});
		});
	}

	const changeAvatar = (event: any) => {
		if(event.target.files.length > 0){
			const file = event.target.files[0]
			setAvatarFile(file)
		}
	}
	const removeAvatar = () => {
		setAvatarFile(null)
	}
	return (
		<>
			<div className={'d-flex flex-column w-100'}>
				<div className={'form-wrapper'}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className='profile-avatar d-flex flex-column align-items-center mb-4 w-100'>
							<div className={'position-relative profile-avatar-image'}>
								<img src={avatar ?? 'https://placehold.co/120x120' }
								     className='rounded-circle' alt='logo'/>
								{avatarFile === null && (<div className={'avatar-file'}>
									<input type="file" name={'avatar'} className={'avatar-file'} id="upload" hidden onChange={changeAvatar.bind(this)}/>
									<label htmlFor="upload">
										<>
											<BiEditAlt />
										</>
									</label>
								</div>)}
								{avatarFile && (<button className={'avatar-file-remove'} onClick={removeAvatar}>
									<>
										<FaTimes />
									</>
								</button>)}
							</div>
						</div>
						<div className="form-group mb-3">
							<label className={'form-label'}>Full Name</label>
							<Input
								placeholder='Nama Lengkap'
								name='fullname'
								register={register}
								errors={errors}
								block={true}
								rules={{
									required: "Nama Lengkap tidak boleh kosong",
								}}
							/>
						</div>
						<div className="form-group mb-3">
							<label className={'form-label'}>Email</label>
							<Input
								placeholder='Email'
								name='email'
								register={register}
								errors={errors}
								block={true}
								rules={{
									required: "Email tidak boleh kosong",
								}}
								disabled={true}
							/>
						</div>
						<div className="form-group mb-3">
							<label className={'form-label'}>Nomor Telepon</label>
							<Input
								placeholder='Nomor Telepon'
								name='phone'
								register={register}
								errors={errors}
								block={true}
								rules={{
									required: "Nomor Telepon tidak boleh kosong",
								}}
							/>
						</div>
						<div className="form-group mb-3">
							<label className={'form-label'}>Tanggal Lahir</label>
							<Controller
								control={control}
								name='dob'
								rules={{
									required: "Tanggal Lahir tidak boleh kosong",
								}}
								render={({ field ,formState}) => (
									<>
										<DatePicker
											dateFormat="yyyy-MM-dd"
											placeholderText='Pilih Tanggal Lahir'
											onChange={(date) => field.onChange(date)}
											selected={field.value}
											className={'form-control'}
											locale="id-ID"
										/>
									</>
								)}
							/>
							{errors?.dob && (
								<p className="text-danger fst-italic mb-2">
									<>
										{errors?.dob?.message}
									</>
								</p>
							)}
						</div>
						<div className="form-group mb-3">
							<label className={'form-label'}>Jenis Kelamin</label>
							<Select
								placeholder='Pilih Jenis Kelamin'
								name='gender'
								register={register}
								errors={errors}
								block={true}
								rules={{
									required: "Jenis kelamin tidak boleh kosong",
								}}
								options={genderOptions}
							/>
						</div>
						<div className="form-group mb-5">
							<Button primary block={true}>
								Sunting
							</Button>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = (state: any) => {
	return {
		user: state.user,
		UI: state.UI,
	};
};
export default connect(mapStateToProps,null)(ProfileEdit);
