import React, {useEffect} from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import {useDispatch} from 'react-redux'
import CourseCard, { CourseCardMe } from '../../components/Layout/Course/CourseCard'
import {useInfiniteQuery} from "react-query";
import {authenticatedRequestRaw} from "../../services/api.service";
import {MyCourses as MyCoursesType} from "../../typings/course";
import {LOADING_UI, UNLOADING_UI} from "../../redux/types";
import { useInView } from 'react-intersection-observer'

const MyCourse: React.FC<RouteComponentProps> = (props) =>{
  const { ref, inView } = useInView()
  const history  = useHistory();
  const dispatch = useDispatch();
  const { data, refetch, isFetching,
    isFetchingNextPage,
    fetchNextPage } = useInfiniteQuery(['my-courses'], async ({ pageParam = 1 }) => {
    const response = await authenticatedRequestRaw('GET','/course/my',{ page: pageParam })
    return response?.data?.data?.courses
  },{
    getPreviousPageParam: (firstPage: any) => !!firstPage?.courses?.meta?.next_page_url ? firstPage?.courses?.meta?.current_page - 1 : undefined,
    getNextPageParam: (firstPage: any) => !!firstPage?.courses?.meta?.next_page_url ? firstPage?.courses?.meta?.current_page + 1 : undefined,
  })
  useEffect(() => {
    if(isFetching){
      dispatch({ type: LOADING_UI })
    }else{
      dispatch({ type: UNLOADING_UI })
    }
  },[isFetching, dispatch])
  useEffect(() => {
    console.log('inview', inView)
  },[inView])
  useEffect(() => {
    console.log('inview', data)
  },[data])
  return (
    <>
      {
        data?.pages.map((p, idx) => (
          <React.Fragment key={`pages-my-course-${idx}`}>
            {
              p.data.map((c: MyCoursesType, idx2: number) => (
                <CourseCardMe {...c} key={`item-my-course-${idx2}`}></CourseCardMe>
              ))
            }
          </React.Fragment>
        ))
      }
      <span ref={ref} className={'edge-infinite'}></span>
    </>
  )
}
export default MyCourse
