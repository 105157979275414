import React, {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {authenticatedRequestRaw} from "../../../services/api.service";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {ActivityType} from "../../../typings/activity";
import Quiz from "./partials/quiz";
import Header from "../../../components/Layout/Header/header";
import {Modal} from "react-bootstrap";
import Button from "../../../components/UI/Button";
import Task from "./partials/task";
import FeedbackQuiz from "./partials/feedback-quiz";
import Reading from "./partials/reading";
import Video from "./partials/video";
import FeedbackTask from "./partials/feedback-task";

const Activity: React.FC = () => {
	const history  = useHistory();
	const dispatch = useDispatch();
	const params:any  = useParams();
	const [activityId, setActivityId] = useState<number | null>(null);
	const { data: activity, refetch, isFetching } = useQuery<ActivityType>('activityDetail', async () => {
		const response = await authenticatedRequestRaw('GET','/course/activity/' + activityId)
		return response?.data?.data
	}, {enabled: false})
	const [modalBack, showModalBack] = useState(false)
	useEffect(() => {
		if(isNaN(params?.id)){
			history.push('/dashboard')
		}
		setActivityId(Number(params?.id));
	},[]) // eslint-disable-line react-hooks/exhaustive-deps
	useEffect(() => {
		if(activityId){
			refetch();
		}
	},[activityId]);
	useEffect(() => {
		if(activity){
			console.log('data', activity);
		}
	},[activity]);
	const render = () => {
		if(activity){
			switch (activity.type){
				case 'quiz':
					if(activity.memberActivities){
						return <FeedbackQuiz activity={activity} />
					}
					return <Quiz activity={activity} />
				case 'task':
					if(activity.memberActivities){
						return <FeedbackTask activity={activity} />
					}
					return <Task activity={activity} />
				case 'reading':
					return <Reading activity={activity} />
				case 'video':
					return <Video activity={activity} />
			}
		}
	}
	const getTitle = () => {
		if(activity){
			switch (activity.type){
				case 'quiz':
					return 'Kuis'
				case 'task':
					return 'Tugas'
				case 'reading':
					return 'Bacaan'
				case 'video':
					return 'Video'
			}
		}
	}
	return <>
		<Header title={getTitle()} onBack={() => {
			if(activity?.type === 'quiz' && activity?.memberActivities === null){
				showModalBack(true)
			}else{
				history.push(`/course/${activity?.course_id}`)
			}
		}} />
		<div className={'main-content w-100'}>
			{render()}
		</div>
		<Modal show={modalBack} onHide={()=>{ showModalBack(false) }}>
			<Modal.Body>Anda yakin akan meninggalkan halaman ini? data anda tidak akan tersimpan</Modal.Body>
			<Modal.Footer>
				<Button secondary onClick={()=>{ showModalBack(false) }}>
					Batal
				</Button>
				<Button primary className={'min-w-75px'} onClick={() => history.push(`/course/${activity?.course_id}`)}>
					Ya
				</Button>
			</Modal.Footer>
		</Modal>
	</>
}

export default Activity
