import { RouteComponentProps } from 'react-router-dom'
import { InitialStateUIType, InitialStateUserType } from '../../typings/state'
import React, { useEffect } from 'react'
import './auth.scss';
import { useForm } from 'react-hook-form'
import Input from '../../components/UI/Input'
import Button from '../../components/UI/Button'
import { signup, loginSocial } from '../../redux/actions/userAction'
import { connect } from 'react-redux'
import SocialButton from '../../components/Layout/Auth/SocialButton'
import { FaEnvelope, FaExclamationCircle, FaKey, FaUser } from 'react-icons/fa'
import AuthHeader from '../../components/Layout/Auth/Header'
import { EmailValidation, PasswordValidation } from '../../utils/common'

interface SignupProps extends RouteComponentProps{
  user: InitialStateUserType;
  UI: InitialStateUIType;
  signup: Function;
  loginSocial: Function;
}

const Signup: React.FC<SignupProps> = (props:SignupProps) => {
  const { register, handleSubmit, formState: { errors }, getValues } = useForm();
  const goBack = () =>{
    props.history.goBack();
  }
  const goToLogin = () =>{
    props.history.push('/auth/login');
  }
  const onSubmit = async (data: any) => {
    await props.signup(data);
  }
  const ShowError = () =>{
    return (
      <div className='alert alert-danger mb-3 d-flex align-items-center'>
        <FaExclamationCircle />
        <p className='m-0 mx-2'>{props.UI.message}</p>
      </div>
    )
  }

  useEffect(()=>{
    if(props.user.authenticated){
      props.history.push('/competency')
    }
  },[props.user])
  return (
    <div className={'main-content'}>
      <AuthHeader back={goBack} />
      <h1 className={'mb-5 fw-bolder'}>Create Your Account</h1>
      <div className={'form-wrapper'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {props.UI.error && <ShowError />}
          <div className="form-group mb-3">
            <Input
              placeholder='Full Name'
              name='fullname'
              register={register}
              errors={errors}
              block={true}
              rules={{
                required: "Full Name is required",
              }}
              Icon={FaUser}
            />
          </div>
          <div className="form-group mb-3">
            <Input
              placeholder='Email'
              name='email'
              register={register}
              errors={errors}
              block={true}
              rules={{
                required: "Email is required",
                validate:EmailValidation
              }}
              Icon={FaEnvelope}
            />
          </div>
          <div className="form-group mb-3">
            <Input
              placeholder='Password'
              name='password'
              type='password'
              register={register}
              errors={errors}
              block={true}
              rules={{
                required: "Password is required",
                minLength: {
                  value: 8,
                  message: "minimum length 8"
                },
                validate:PasswordValidation
              }}
              Icon={FaKey}
            />
          </div>
          <div className="form-group mb-3">
            <Input
              placeholder='Confirm Your Password'
              name='password_confirmation'
              type='password'
              register={register}
              errors={errors}
              block={true}
              rules={{
                required: "Confirm Password is required",
                validate: (value) => {
                  const { password } = getValues();
                  return password === value || "Passwords should match!";
                },
                minLength: {
                  value: 8,
                  message: "minimum length 8"
                }
              }}
              Icon={FaKey}
            />
          </div>

          <div className="form-group mb-5">
            <Button primary block={true}>
              Sign Up
            </Button>
          </div>
        </form>
      </div>
      <div className="or-wrapper mb-4">
        <div className='or-container'>
          <div className='or-content'>or continue with</div>
        </div>
      </div>
      <SocialButton  {...props} className='mb-3' />
      <div className='d-flex w-100 justify-content-center'>
        <span className='text-muted'>Already Have an Account?</span>
        <a className='text-primary mx-2' onClick={goToLogin.bind(this)}>Log In</a>
      </div>
    </div>
  )
}
const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    UI: state.UI,
  };
};
const mapDispatchToProps = { signup, loginSocial };
export default connect(mapStateToProps,mapDispatchToProps)(Signup);
