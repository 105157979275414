import React, {useEffect, useState} from "react";
import {RouteComponentProps, useHistory, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import Header from "../../components/Layout/Header/header";
import {CourseRatingsFlat} from "../../components/Layout/Course/CourseRatings";
import CourseRatingFilter from "../../components/Layout/Course/CourseRatingFilter";
import {ratingsList} from "../../utils/static-data";
import CourseReview from "../../components/Layout/Course/CourseReview";
import {useInfiniteQuery} from "react-query";
import {authenticatedRequestRaw} from "../../services/api.service";
import {LOADING_UI, UNLOADING_UI} from "../../redux/types";
import {useInView} from "react-intersection-observer";
import {CourseReview as CourseReviewType} from "../../typings/course";

const CourseReviews: React.FC<RouteComponentProps> = (props) => {
	const { ref, inView } = useInView()
	const history  = useHistory();
	const dispatch = useDispatch();
	const params:any  = useParams();
	const [courseId, setCourseId] = useState<number | null>(null);
	const [rating, setRating] = useState<number | null>(null);
	const [additionalInfo, setAdditionalInfo] = useState<{
		rating: number,
		totalReviews: number
	}>({
		rating: 0,
		totalReviews: 0
	})
	const { data, refetch, isFetching,
		isFetchingNextPage,
		fetchNextPage } = useInfiniteQuery(['course-reviews', courseId, rating], async ({ pageParam = 1 }) => {
			if(courseId){
				const response = await authenticatedRequestRaw('POST','/course/reviews',{
					page: pageParam,
					course_id: courseId,
					rate: rating,
				})
				setAdditionalInfo({
					rating: response?.data?.additionalInfo?.rating ?? 0,
					totalReviews: response?.data?.additionalInfo?.totalReviews ?? 0,
				})
				return response?.data
			}else{
				return []
			}
	},{
		getPreviousPageParam: (firstPage: any) => !!firstPage?.meta?.next_page_url ? firstPage?.meta?.current_page - 1 : undefined,
		getNextPageParam: (firstPage: any) => !!firstPage?.meta?.next_page_url ? firstPage?.meta?.current_page + 1 : undefined,
	})
	useEffect(() => {
		if(isNaN(params?.id)){
			history.push('/dashboard')
		}
		setCourseId(Number(params?.id));
	},[]) // eslint-disable-line react-hooks/exhaustive-deps
	useEffect(() => {
		if(isFetching){
			dispatch({ type: LOADING_UI })
		}else{
			dispatch({ type: UNLOADING_UI })
		}
	},[isFetching, dispatch])
	useEffect(() => {
		if(inView){
			fetchNextPage();
		}
	},[inView])
	const handleRatingFilter = (value: number | null) => {
		setRating(value);
	}
	return <>
		<Header title={'Ulasan'} onBack={() => {
			history.push(`/course/${courseId}`)
		}} />
		<div className={'main-content w-100'}>
			<CourseRatingsFlat rating={additionalInfo.rating} totalReviews={additionalInfo.totalReviews} courseId={courseId ?? 0}/>
			<CourseRatingFilter ratings={ratingsList} rating={rating} onClick={handleRatingFilter} />
			<div className='d-flex flex-column mt-3 w-100'>
				{
					data?.pages?.map((p, idx) => (
						<React.Fragment key={`pages-my-course-${idx}`}>
							{
								p?.data?.map((c: CourseReviewType, idx2: number) => (
									<CourseReview {...c} key={`rev-${courseId}-${idx2}`}/>
								))
							}
						</React.Fragment>
					))
				}
				<span ref={ref} className={'edge-infinite'}></span>
			</div>
		</div>
	</>
}
export default CourseReviews
