import './course-card.scss';
import {BsStarHalf, BsStarFill, BsStar} from 'react-icons/bs'
import {BiBook} from 'react-icons/bi'
import { useHistory } from 'react-router-dom'
import {Course, MyCourses} from "../../../typings/course";
import React from "react";
import {IconType} from "react-icons";
import {BEUrl} from "../../../utils/common";
const CourseCard: React.FC<Course> = (props: Course) => {
  const history = useHistory();
  const detail = (id: number) => {
    history.push(`/course/${id}`)
  }
  let IconElm: IconType = BsStarFill
  if(props.rating === 0){
    IconElm = BsStar
  }else if(props.rating > 0 && props.rating < 5){
    IconElm = BsStarHalf
  }
  return (
    <div className='card-course' onClick={() => detail(props.id)}>
      <div className='cc-content'>
        <div className='cc-image'>
          <img src={props.image}
               alt={'logo'}
          />
        </div>
        <div className='cc-content-info'>
          <span className="badge course-category-label mb-1">{props.category}</span>
          <h6 className='fw-bold mb-1 cc-title'>{props.name}</h6>
          <small className='text-muted cc-organization'>{props.organization}</small>
          <div className='cc-price fw-bold mb-2'>
            Rp.{ props.price.toLocaleString('ID-id') }
          </div>
          <div className='d-flex align-items-center'>
            <IconElm size='1rem' className='cc-rating'/><span>{props.rating}</span>
            <span className='text-muted mx-2'>|</span>
            <BiBook size='1rem' className='cc-topic'/><span>{props.totalActivities} Materi</span>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CourseCard;

export const CourseCardFeatured = () =>{
  return null;
}

export const CourseCardMe = (props: MyCourses) => {
  const history = useHistory();
  const detail = (id: number) => {
    history.push(`/course/${id}`)
  }

  const getPercentage = () => {
    return Number(((props.memberActivities/props.totalActivities) * 100).toFixed(2))
  }
  return (
    <div className='card-course' onClick={() => detail(props.id)}>
      <div className='cc-content'>
        <div className='cc-image'>
          <img src={`${BEUrl()}static/course/${props.logo}`}
               alt={'logo'}
          />
        </div>
        <div className='cc-content-info w-100'>
          <span className="badge course-category-label mb-1">{props.category}</span>
          <h6 className='fw-bold mb-1 cc-title'>{props.name}</h6>
          <small className='text-muted fs-7 mb-2'>2 jam 30 menit</small>
          <div className='d-flex w-100 align-items-center'>
            <div className="progress w-100 me-2">
              <div className={`progress-bar ${getPercentage() >= 100 ? 'bg-success' : 'bg-info'}`} style={{width:`${getPercentage()}%`}} role="progressbar" aria-valuenow={getPercentage()}
                   aria-valuemin={0} aria-valuemax={100}></div>
            </div>
            <span className='fs-7 text-muted'>{props.memberActivities}/{props.totalActivities}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
