import './checkout-bottom.scss';
import Button from '../../../components/UI/Button'
import {Course, CourseDetail} from "../../../typings/course";
import {useHistory} from "react-router-dom";
interface ICourseDetailBottom {
  course: Course
  onPay: Function
  paymentMethod: number | null
}
const CheckoutBottom = ({course, onPay, paymentMethod}: ICourseDetailBottom) => {
  const history = useHistory();
  const goToCheckout = () => {
    history.push(`/payment/checkout/${course.id}`)
  }
  return (
    <>
      <div className='cd-bottom-container'>
        <div className='cd-bottom-content'>
          <Button primary={true} block={true} disabled={paymentMethod === null} onClick={()=> onPay()}  >
            Bayar (Rp{course.price.toLocaleString('id-ID')})
          </Button>
        </div>
      </div>
    </>
  )
}
export default CheckoutBottom;
