import { compose } from 'redux'
import { useDispatch } from 'react-redux';
import { configureStore, combineReducers, MiddlewareArray } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import userReducer from './reducers/userReducer'
import UIReducer from './reducers/UIReducer'
const initialState = {};
const middleware = [thunk];

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: typeof compose;
  }
}
const reducer = combineReducers({
  user: userReducer,
  UI: UIReducer,
});
const store = configureStore({
  reducer: reducer,
  middleware: new MiddlewareArray().concat(middleware),
  preloadedState: initialState
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const UseAppDispatch = () => useDispatch<AppDispatch>();

export default store;
