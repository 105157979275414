import {ActivityType, MemberAnswerFileType} from "../../../../typings/activity";
import React from "react";
import './feedback-task.scss'
import {Redirect} from "react-router-dom";
import dayjs from "dayjs";
import {RiDownload2Fill} from 'react-icons/ri'
interface ITask {
	activity: ActivityType
}
const webUrl = process.env.REACT_APP_WEB_URL;
const FeedbackTask: React.FC<ITask> = ({ activity}) => {
	if(activity?.memberActivities){
		const { score, feedback, scored_date, member_answer, created_at } = activity?.memberActivities
		const memberAnswers: MemberAnswerFileType[] = JSON.parse(member_answer as any)
		return <>
			<div className={'border py-3 position-relative d-flex justify-content-center w-100 rounded-top border-bottom-0'}>
				<div className="row w-100">
					<div className="col-6 border-end d-flex flex-column">
						<span className="fs-7 mb-1">Diunggah Tanggal</span>
						<span className={'fs-7 fw-bold'}>{dayjs(created_at).format('LLL')}</span>
						<div className={'my-2 w-100'}></div>
						<span className="fs-7 mb-1">Dinilai Tanggal</span>
						<span className={'fs-7 fw-bold'}>{scored_date ? dayjs(scored_date).format('LLL') : 'Menunggu Penilaian'}</span>
					</div>
					<div className="col-6 d-flex flex-column align-items-center justify-content-center">
						<span className={'fs-7 mb-2'}>Nilai Anda</span>
						<span className="score-title mb-2">{score ?? '-'}</span>
					</div>
				</div>
			</div>
			<div className="d-flex w-100 flex-column mb-3 rounded-bottom border">
				<div className="d-flex w-100 p-3 border-bottom bg-light fw-bold">Umpan Balik</div>
				<div className="d-flex w-100 p-3">{ feedback ?? '-' }</div>
			</div>
			{
				memberAnswers.map((f) => {
					return <>
						<div className="bg-light p-3 rounded-2 d-flex justify-content-between align-items-center w-100 mb-3">
							<div className="d-flex flex-column me-3 gap-2">
								<span className="fw-bold fs-4" style={{ wordBreak: 'break-all' }}>{f.file_name}</span>
								<div className="d-flex gap-2 align-items-center">
									<span className="fs-7">File Size: {f.file_size}</span>
									<span className="fs-7">File Type: {f.file_ext}</span>
								</div>
							</div>
							<a href={`${webUrl}static/task/${f.file_encrypt}`} download={ f.file_name }>
								<RiDownload2Fill size={'1.5rem'}/>
							</a>
						</div>
					</>
				})
			}
		</>
	}else{
		return <Redirect to={`/course/${activity?.course_id}`} />
	}

}
export default FeedbackTask
