import React, {useEffect, useState} from "react";
import {BsDownload, BsEye} from 'react-icons/bs'
import { pdfjs, Document, Page } from 'react-pdf';
import {ActivityType} from "../../../../typings/activity";
import {BEUrl, getExtFile} from "../../../../utils/common";
import {READING_TYPE_FILE, READING_TYPE_TEXT} from "../../../../constant/activity";
import Button from "../../../../components/UI/Button";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './reading.scss'
import type { PDFDocumentProxy } from 'pdfjs-dist';
import {Modal} from "react-bootstrap";
import BlockUi from 'react-block-ui'
import {authenticatedRequestRaw} from "../../../../services/api.service";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
interface IReading {
	activity: ActivityType
}
type PDFFile = string | File | null;
const Reading: React.FC<IReading> = ({activity}) => {
	const [file, setFile] = useState<PDFFile>(null);
	const [loadingPDF, setLoadingPDF] = useState<boolean>(false);
	const [numPages, setNumPages] = useState<number>();
	const [activePage, setActivePage] = useState<number>(1);
	const [showModalPDF, setShowModalPDF] = useState<boolean>(false);
	const fileExt: string = getExtFile(activity?.activity_url || "");
	const [isDone, setIsDone] = useState<boolean>(!!activity?.memberActivities)

	const saveActivity = async () => {
		if(!isDone){
			await authenticatedRequestRaw('POST','/course/activity/submit',{
				activity_id: activity?.id,
				course_id: activity?.course_id,
			})
			setIsDone(true);
		}
	}

	useEffect(() => {
		if(activity && activity?.activity_type === READING_TYPE_TEXT){
			// if(activity)
			saveActivity()
		}
	},[activity,isDone])
	const setFiles = () => {
		if(activity){
			setActivePage(1)
			if(file === null){
				setShowModalPDF(true);
				setLoadingPDF(true)
				setFile(`${BEUrl()}static/reading/${activity.activity_url}`)

			}else{
				setShowModalPDF(true);
				setLoadingPDF(true)
			}
		}
	}
	const DisplayAction = () => {
		if(activity){
			if(activity.activity_type === READING_TYPE_FILE){
				return (
					<div className="d-flex justify-content-center gap-2">
						<a className="btn btn-primary d-flex align-items-center gap-2"
						   href={`${BEUrl()}static/reading/${activity.activity_url}`}
						   download={`${activity.title}.${fileExt}`} target="_blank">
							<BsDownload />
							<span>Unduh</span>
						</a>
						<Button outlinePrimary >
							<div className="d-flex align-items-center gap-2" onClick={setFiles}>
								<BsEye />
								<span>Baca</span>
							</div>
						</Button>
					</div>
				)
			}
		}
		return null
	}
	const onDocumentLoadSuccess = ({ numPages: nextNumPages }: PDFDocumentProxy) => {
		setNumPages(nextNumPages);
		setLoadingPDF(false)
		if(!isDone){
			saveActivity()
		}

	}
	const PDFPagination = () => {
		return <div className={"pdf-pagination"}>
			<div className="pdf-pagination-content">
				<button onClick={()=>{
					if(activePage > 1){
						setActivePage(activePage-1);
					}
				}}>&lt;</button>
				<div className="page-info">
					{activePage} dari {numPages}
				</div>
				<button onClick={()=>{
					if(numPages){
						if(activePage < numPages){
							setActivePage(activePage+1);
						}
					}
				}}>&gt;</button>
			</div>
		</div>
	}
	return <div className="d-flex flex-column w-100">
		<h2 className="fw-bold mb-3">{activity?.title}</h2>
		<div className={'border p-3 rounded-2 position-relative mb-3'}>
			<div dangerouslySetInnerHTML={{ __html: activity?.description || '' }} />
		</div>
		<DisplayAction />

		<Modal show={showModalPDF} onHide={()=>{ setShowModalPDF(false) }} fullscreen={true}>
			<Modal.Header closeButton>
				<Modal.Title>{ activity?.title }</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{overflow:'hidden'}}>
				<BlockUi tag="div" blocking={loadingPDF} message={"Loading, please wait"}>
					{
						file && (
							<>
								{ !loadingPDF && <PDFPagination /> }
								<div className="pdf-container">
									<Document file={file} onLoadSuccess={onDocumentLoadSuccess} >
										<Page pageNumber={activePage} scale={5.0}/>
									</Document>
								</div>
								{ !loadingPDF && <PDFPagination /> }
							</>

						)
					}
				</BlockUi>
			</Modal.Body>
		</Modal>
	</div>
}

export default Reading
