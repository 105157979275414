import React, {useEffect, useState} from 'react';
import videojs from 'video.js';
import "videojs-youtube";
import 'video.js/dist/video-js.css';
import {VideoJsOptionType} from '../typings/common'

export const VideoJS = (props: {
	options: VideoJsOptionType,
	onReady: any
}) => {
	const videoRef = React.useRef<any>(null);
	const playerRef = React.useRef(null);
	const {options, onReady} = props;
	React.useEffect(() => {
		if (!playerRef.current) {
			const videoElement = document.createElement("video-js");
			videoElement.classList.add('vjs-big-play-centered');
			if(videoRef?.current){
				//@ts-ignore
				videoRef.current.appendChild(videoElement);
			}
			//@ts-ignore
			const player: any = playerRef.current = videojs(videoElement, {
				autoplay: options.autoplay,
				controls: options.controls,
				responsive: options.responsive,
				fluid: options.fluid,
			}, () => {
				videojs.log('player is ready');
				onReady && onReady(player);
			});
		} else {
			const player: any = playerRef.current;
			//@ts-ignore
			player.src(options.sources);
		}
	}, [options, videoRef]);

	React.useEffect(()=>{
		if(playerRef.current && options.sources.length>0){
			const player: any = playerRef.current;
			player.src(options.sources);
		}
	},[options.sources,playerRef])

	// Dispose the Video.js player when the functional component unmounts
	React.useEffect(() => {
		const player: any = playerRef.current;

		return () => {
			if (player && !player.isDisposed()) {
				player.dispose();
				playerRef.current = null;
			}
		};
	}, [playerRef]);

	return (
		<div data-vjs-player="" style={{
			height: '100%'
		}} >
			<div ref={videoRef} style={{
				height: '100%'
			}}/>
		</div>
	);
}

export default VideoJS;
