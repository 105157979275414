import * as React from 'react'
import { RouteComponentProps, Switch, useRouteMatch } from 'react-router-dom'
import CompetencyTest from '../pages/competency-test/competency-test'
import { AuthedRoutes } from '../components/AuthedRoutes'
import Detail from "../pages/competency-test/detail";
import TestDetail from "../pages/competency-test/test-detail";
export const competencyTestRoutes = (props: RouteComponentProps) =>{
	// eslint-disable-next-line react-hooks/rules-of-hooks
	let { path } = useRouteMatch();
	return (
		<>
			<Switch>
				<AuthedRoutes menu='competency' path={`${path}/`}
				              exact={true} component={CompetencyTest}
				              headerTitle='Daftar Tes Kompetensi'
				></AuthedRoutes>
				<AuthedRoutes menu='competency' path={`${path}/:id`}
				              exact={true} component={Detail}
				              excludeHeader={true}
				              headerTitle='Tes Kompetensi'
				></AuthedRoutes>
				<AuthedRoutes menu='competency' path={`${path}/:id/test`}
				              exact={true} component={TestDetail}
				              excludeHeader={true}
				              excludeBottom={true}
				              noPadding
				              headerTitle='Tes Kompetensi'
				></AuthedRoutes>
			</Switch>
		</>
	)
}

