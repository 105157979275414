import './tab-content-material.scss';
import {BiBook, BiPlay, BiQuestionMark, BiVideo, BiFile, BiTask, BiCheck} from 'react-icons/bi'
import Icon from '../../../components/UI/Icon'
import {CourseActivities, CourseDetail} from "../../../typings/course";
import {IconType} from "react-icons/lib";
import {useHistory} from "react-router-dom";
import React from 'react';

interface IMaterialProps {
  activities: CourseActivities[]
  course: CourseDetail
}

interface IMaterialDetailProps {
  activity: CourseActivities
  course: CourseDetail
  history: any
}

const TabContentMaterialDetail = ({activity, course, history}: IMaterialDetailProps) => {
  const finished = activity.score !== null;
  const scored = activity.scored_date !== null;
  let icons: IconType = BiFile
  switch (activity.type) {
    case 'quiz':
      icons = BiQuestionMark
      break
    case 'task':
      icons = BiTask
      break
    case 'reading':
      icons = BiBook
      break;
    case 'video':
      icons = BiVideo
      break;
  }
  const activityAction = (activity: CourseActivities) => {
    if(activity.canAccess){
      history.push(`/course/activity/${activity.id}`)
    }
  }

  const DisplayAction = () => {
    if(finished && ['task','quiz'].includes(activity?.type)){
      return <span className="fs-3 fw-bold text-success">{activity.score}</span>
    }else if(finished && ['reading','video'].includes(activity?.type)){
      return <Icon IconElm={BiCheck} variant={'success'} onClick={()=>activityAction(activity)}/>
    }else{
      return <Icon IconElm={BiPlay} onClick={()=>activityAction(activity)} disabled={!activity.canAccess}/>
    }
  }

  const displayInfo = () => {
    let text = '';
    let className = 'text-bg-secondary';
    switch (activity.type) {
      case 'quiz':
        if(finished && scored){
          text = 'Sudah Dinilai';
          className = 'text-bg-success';
        }else if(finished){
          text = 'Menunggu Penilaian'
          className = 'text-bg-warning';
        }else{
          text = 'Belum Mengerjakan'
        }
        break;
      case 'task':
        if(finished && scored){
          text = 'Sudah Dinilai';
          className = 'text-bg-success';
        }else if(finished){
          text = 'Menunggu Penilaian'
          className = 'text-bg-warning';
        }else{
          text = 'Belum Mengumpulkan'
        }
        break;
      case 'reading':
        text = finished ? 'Sudah Membaca' : 'Belum Membaca';
        if(finished){
          className = 'text-bg-success'
        }
        break;
      case 'video':
        text = finished ? 'Sudah Menonton' : 'Belum Menonton';
        if(finished){
          className = 'text-bg-success'
        }
        break;
    }
    return (
      <span className={`material-info-duration mb-2 badge ${className}`}>{ text }</span>
    )
  }

  return (<div className='material-item'>
    <div className='material-item-left'>
      <div className='material-item-icon'>
        <Icon IconElm={icons} variant={finished ? 'success' : 'light'} sizeWrapper={'3rem'} size={'1.5rem'} />
      </div>
      <div className='material-item-info align-items-start'>
        <span className='fw-bold mb-2'>{activity.title}</span>
        {
          course.isJoined ? displayInfo() : (
            <span className={'material-info-duration mb-2'}>
              { activity.duration_info }
            </span>
          )
        }
        {
          ['quiz','task'].includes(activity.type) && (
            <span
              className="material-info-duration text-primary fw-bold cursor-pointer text-decoration-underline"
              onClick={()=>activityAction(activity)}
            >Detail</span>
          )
        }
      </div>
    </div>
    <div className='material-item-right'>
      <DisplayAction />
    </div>
  </div>);
}

const TabContentMaterial = ({activities, course}: IMaterialProps) => {
  const history = useHistory();
  let activitiesData = [...activities];
  if(course.isJoined && activities.length > 0){
    if(activities[0].score!==null){
      activitiesData = activitiesData.map(a => {
        return { ...a,canAccess: a.score!==null}
      })
      let lastIndexScore: any = null;
      activitiesData.forEach((a, idx) => {
        if(!a.canAccess && lastIndexScore===null){
          if(activitiesData[idx-1].canAccess){
            lastIndexScore = idx;
          }
        }
      })
      if(lastIndexScore!==null){
        activitiesData[lastIndexScore].canAccess = true
      }
    }else{
      activitiesData[0].canAccess = true
    }
  }
  return (
    <>
      <div className='material-list'>
        {
          activitiesData.map((activity, idx) => (
            <React.Fragment key={`material${idx}`}>
              <TabContentMaterialDetail activity={activity} course={course} history={history}  />
            </React.Fragment>
          ))
        }
      </div>
    </>
  );
}
export default TabContentMaterial
