export const PM_CC = 1
export const PM_VA_BCA = 2
export const PM_VA_BRI = 3
export const PM_VA_BNI = 4
export const PM_GOPAY = 5
export const PM_QRIS = 6
export const PM_SHOPEE = 7


export const PAYMENT_PENDING = 0
export const PAYMENT_SUCCESS = 1
export const PAYMENT_FAILED = 2
export const PAYMENT_CANCELED = 3
export const PAYMENT_EXPIRED = 4
