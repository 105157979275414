import * as React from "react";
import {
  RouteComponentProps,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import Account from "../pages/account/account";
import MyCourse from '../pages/account/my-course'
import { AuthedRoutes } from "../components/AuthedRoutes";
import ProfileEdit from "../pages/account/profile-edit";
import MyCompetencies from "../pages/account/my-competencies";
export const accountRoutes = (props: RouteComponentProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <AuthedRoutes
          menu="account"
          path={`${path}/`}
          exact={true}
          component={Account}
          headerTitle="Akun Saya"
        ></AuthedRoutes>
        <AuthedRoutes
          menu="course"
          path={`${path}/my-course`}
          exact={true}
          component={MyCourse}
          headerTitle="Kursus Saya"
        ></AuthedRoutes>
        <AuthedRoutes
          menu="competency"
          path={`${path}/my-competencies`}
          exact={true}
          component={MyCompetencies}
          headerTitle="Tes Kompetensi Saya"
        ></AuthedRoutes>
        <AuthedRoutes
          menu="account"
          path={`${path}/profile`}
          exact={true}
          component={ProfileEdit}
          headerTitle="Sunting Profil"
        ></AuthedRoutes>
      </Switch>
    </>
  );
};
