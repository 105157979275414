import * as React from "react";
import {
	UseFormRegister,
	FieldValues,
	FieldValue,
	DeepMap,
	FieldError, RegisterOptions,
} from 'react-hook-form'
import {Options} from "../../typings/common";

interface ISelect {
	placeholder: string;
	name?: FieldValue<any>;
	className?: string;
	register?: UseFormRegister<FieldValues>;
	errors?: DeepMap<FieldValues, FieldError>;
	defaultValue?: any;
	required?: boolean;
	block?: boolean;
	disabled?: boolean;
	rules?:RegisterOptions;
	options: Options[]
}

const Select: React.FC<ISelect> = ({
	                                 placeholder,
	                                 className = "",
	                                 name,
	                                 register,
	                                 errors,
	                                 defaultValue,
	                                 required,
	                                 block,
	                                 disabled,
	                                 rules,
	                                 options,
                                 }: ISelect) => {
	let styleInput = `form-control ${className}`;
	let styleWrapper = `input-wrapper`;
	if (block) {
		styleInput = `form-control w-full ${className}`;
	}
	const registerOptions = rules ?? {}
	const SelectPlain = () => {
		return (
			<div className={styleWrapper}>
				<select
					required={required}
					className={styleInput}
					placeholder={placeholder}
					name={name}
					disabled={disabled}
					defaultValue={defaultValue}
				>
					{
						options.map( (opt,idx) => (
							<option value={opt.value} key={idx}>{opt.label}</option>
						))
					}
				</select>
			</div>
		)
	}

	return register ? (
		<div className={styleWrapper}>
			<select
				required={required}
				className={styleInput}
				placeholder={placeholder}
				defaultValue={defaultValue}
				disabled={disabled}
				{...register(name,registerOptions)}
			>
				<option value={''}>{placeholder}</option>
				{
					options.map( (opt,idx) => (
						<option value={opt.value} key={idx}>{opt.label}</option>
					))
				}
			</select>
			{errors?.[name] && (
				<p className="text-danger fst-italic mb-2">
					{errors[name].message}
				</p>
			)}
		</div>
	) : <SelectPlain />;
};
export default Select;
