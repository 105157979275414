import './course-members.scss';
import React from 'react'
import {UserType} from "../../../typings/user";
interface propsCourseMembers{
  members: UserType[]
  totalMembers: number
}
const CourseMembers = (props: propsCourseMembers) => {
  return (
    <div className='cd-members-container d-flex align-items-start flex-column'>
      <div className='cd-members mb-2'>
        {
          props.members.map((m,index)=>{
            return (
              <span className='cd-member' key={`av-${index}-${new Date()}`}>
                <img src={m.avatar ?? 'https://placehold.co/100x100'} alt='avatar' />
              </span>
            )
          })
        }
      </div>
      <span>{props.totalMembers} bergabung</span>
    </div>
  )
}

export default CourseMembers;
