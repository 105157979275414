import * as React from 'react'
import { IconType } from 'react-icons'

interface IIcon{
  className?: string
  onClick?: Function
  variant?: 'primary' | 'secondary' | 'light' | 'success'
  disabled?: boolean
  IconElm:IconType;
  size?:string;
  sizeWrapper?:string;
}

const Icon:  React.FC<IIcon> = (props) => {
  let styles = {};
  if(props.sizeWrapper){
    styles = {...styles,width: props.sizeWrapper, height: props.sizeWrapper}
  }
  let onClick: Function = () => {}
  if(props.onClick){
    onClick = props.onClick;
  }
  let classes = 'icon-wrapper ';
  if(props.className){
    classes += props.className+' ';
  }
  if(props.disabled){
    classes += 'icon-disabled ';
  }else{
    if(props.variant){
      classes += `icon-${props.variant} `;
    }else{
      classes += `icon-primary `;
    }
  }
  const size = props.size ?? '1rem';
  const IconElm = props.IconElm;
  return (
    <>
      <div className={classes} style={styles} onClick={()=>{
        onClick();
      }}>
        {<IconElm size={size}/> }
      </div>
    </>
  );
}
export default Icon;
