import React, {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {PaymentCheckout, PaymentWait} from "../../typings/payment";
import {authenticatedRequestRaw} from "../../services/api.service";
import {Course as CourseType} from "../../typings/course";
import {TransactionDetail} from "../../typings/transaction";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {LOADING_UI, UNLOADING_UI} from "../../redux/types";
import Button from "../../components/UI/Button";
import { MobileView } from 'react-device-detect';
import {toast} from 'react-toastify'
import {
	PAYMENT_CANCELED,
	PAYMENT_EXPIRED,
	PAYMENT_FAILED,
	PAYMENT_SUCCESS, PM_CC,
	PM_GOPAY, PM_QRIS,
	PM_SHOPEE, PM_VA_BCA, PM_VA_BNI, PM_VA_BRI
} from "../../constant/payment";
import gopayIcon from '../../assets/images/logo/gopay.png'
import shopeeIcon from '../../assets/images/logo/shopeepay.png'
import qrisIcon from '../../assets/images/logo/qris.png'
import ccIcon from '../../assets/images/logo/cc.png'
import bcaIcon from '../../assets/images/logo/bca.png'
import bniIcon from '../../assets/images/logo/bni.png'
import briIcon from '../../assets/images/logo/bri.png'
import dayjs from "dayjs";
import {BiCopy} from 'react-icons/bi'
import {copyValue} from "../../utils/common";
import Header from "../../components/Layout/Header/header";
import {Modal} from "react-bootstrap";

const Wait: React.FC = () => {
	const history  = useHistory();
	const dispatch = useDispatch();
	const params:any  = useParams();
	const [uid, setUid] = useState<string | null>(null);
	const [qrCode, setQrCode] = useState<string | null>(null);
	const [course, setCourse] = useState<CourseType | null>(null);
	const [transaction, setTransaction] = useState<TransactionDetail | null>(null);
	const [canCheck, setCanCheck] = useState<boolean>(true);
	const [modalCancel, showModalCancel] = useState(false)
	const { data, refetch, isFetching } = useQuery<PaymentWait | undefined>(['payment-wait',uid], async () => {
		if(uid){
			try{
				const response = await authenticatedRequestRaw('GET','/payment/wait/' + uid)
				return response?.data?.data as PaymentWait
			}catch (e) {
				history.push('/course')
			}
		}
		return undefined
	}, {enabled: false})
	const { data: dataCheck, refetch: refetchCheck, isFetching: isFetchCheck } = useQuery<TransactionDetail | undefined>(['payment-wait',uid], async () => {
		if(uid){
			const response = await authenticatedRequestRaw('GET','/payment/check/' + uid)
			return response?.data?.data as TransactionDetail
		}
		return undefined
	}, {enabled: false})
	useEffect(() => {
		if(isFetching){
			dispatch({ type: LOADING_UI })
		}else{
			dispatch({ type: UNLOADING_UI })
		}
	},[isFetching, dispatch])
	useEffect(() => {
		if(params?.uid){
			setUid(params?.uid);
		}
	},[params?.uid]) // eslint-disable-line react-hooks/exhaustive-deps
	useEffect(() => {
		if(uid){
			refetch().then(res => {
				dispatch({ type: UNLOADING_UI })
				if(res?.data?.transaction?.status === PAYMENT_SUCCESS){
					history.push(`/payment/success/${res?.data?.transaction?.uid}`)
				}else if([PAYMENT_FAILED, PAYMENT_EXPIRED, PAYMENT_CANCELED].includes(res?.data?.transaction?.status || 0)){
					history.push(`/payment/failed/${res?.data?.transaction?.uid}`)
				}
				if(res?.data?.course){
					setCourse(res?.data.course)
				}
				if(res?.data?.transaction){
					setTransaction(res?.data.transaction)
				}
				if(res?.data?.qrCode){
					setQrCode(res?.data?.qrCode)
				}
			})
		}
	},[uid])
	const PaymentMethod = () => {
		if(transaction){
			let data = {
				label: '',
				icon: ''
			}
			switch (transaction.payment_method) {
				case PM_GOPAY:
					data.icon = gopayIcon;
					data.label = 'Gopay';
					break;
				case PM_SHOPEE:
					data.icon = shopeeIcon;
					data.label = 'ShopeePay';
					break;
				case PM_QRIS:
					data.icon = qrisIcon;
					data.label = 'QRIS';
					break;
				case PM_CC:
					data.icon = ccIcon;
					data.label = 'Kartu Kredit';
					break;
				case PM_VA_BCA:
					data.icon = bcaIcon;
					data.label = 'BCA Virtual Account';
					break;
				case PM_VA_BRI:
					data.icon = briIcon;
					data.label = 'BRI Virtual Account';
					break;
				case PM_VA_BNI:
					data.icon = bniIcon;
					data.label = 'BNI Virtual Account';
					break;
			}
			return <>
				<div className={'d-flex justify-content-between align-items-center'}>
					<span className={'w-25'}>Metode Pembayaran</span>
					<div className={'d-flex align-items-center w-50'} style={{gap:'10px'}}>
						<img src={data.icon} style={{width:'1.5rem',height: '100%'}}/>
						<span className={'fw-bold'} style={{wordBreak:'break-all'}}>{data.label}</span>
					</div>
				</div>
			</>
		}
		return null
	}
	const openApp = () => {
		if(transaction?.invoice_url_mobile){
			window.location.replace(transaction?.invoice_url_mobile)
		}
	}
	const checkTrans = () => {
		setCanCheck(false)
		dispatch({ type: LOADING_UI })
		refetchCheck().then(res => {
			dispatch({ type: UNLOADING_UI })
			if(res?.data?.status === PAYMENT_SUCCESS){
				history.push(`/payment/success/${uid}`)
			}else if([PAYMENT_FAILED, PAYMENT_EXPIRED, PAYMENT_CANCELED].includes(res?.data?.status || 0)){
				history.push(`/payment/failed/${uid}`)
			}else{
				toast.error("Pembayaran anda belum terproses. Silahkan lakukan pembayaran", {
					position: toast.POSITION.TOP_CENTER
				});
				setTimeout(() => {
					setCanCheck(true)
				},10000)
			}

		})
	}

	const copyVa = () => {
		if(qrCode){
			copyValue(qrCode)
			toast.success("No. Rekening berhasil di-copy!", {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 1000,
				hideProgressBar: true,
				pauseOnHover: false,
			});
		}
	}

	const cancel = async () => {
		showModalCancel(false)
		if(course){
			dispatch({ type: LOADING_UI })
			try{
				await authenticatedRequestRaw('POST','/payment/cancel',{
					uid: transaction?.uid
				})
				dispatch({ type: UNLOADING_UI })
				history.push(`/course/${course.id}`)
				toast.success("Transaksi berhasil dibatalkan", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1000,
					hideProgressBar: true,
					pauseOnHover: false,
				});
			}catch (e) {
				dispatch({ type: UNLOADING_UI })
				toast.error("Terjadi Kesalahan. Ulangi beberapa saat lagi", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1000,
					hideProgressBar: true,
					pauseOnHover: false,
				});
			}


		}

	}

	return <>
		<Header title={'Menunggu Pembayaran'} onBack={() => {
			history.push(`/course/${course?.id}`)
		}} />
		<div className={'main-content w-100'}>
			<div className={'d-flex w-100 flex-column align-items-center pt-3'}>
				<span className={'fs-7 mb-2'}>Lakukan pembayaran sebelum</span>
				<span className={'text-danger fw-bold'}>{ dayjs(transaction?.due_date).format('dddd, MMMM D, YYYY HH:mm') }</span>
			</div>
			<div className={'border border-bottom w-100 my-3'}></div>
			<div className={'d-flex w-100 flex-column align-items-start checkout-container pb-6 pt-0'} style={{padding:'16px'}}>
				<div className={'d-flex w-100 justify-content-between'}>
					<h5 className={'text-muted fw-bold mb-4'}>Detail</h5>
					<span className={'fs-7 fw-bold'}>#{transaction?.uid}</span>
				</div>
				<div className={'d-flex flex-column w-100'}>
					<div className={'d-flex justify-content-between mb-3'}>
						<span>Kursus</span>
						<span className={'fw-bold'}>{course?.name}</span>
					</div>
					<div className={'d-flex justify-content-between mb-3'}>
						<span>Total</span>
						<span className={'fw-bold'}>Rp{transaction?.final_amount?.toLocaleString('id-ID')}</span>
					</div>
					<PaymentMethod />
				</div>
				<div className={'border border-bottom w-100 my-3'}></div>
				<div className={'d-flex flex-column w-100'}>
					<div className={'d-flex justify-content-center mb-3'}>
						{ qrCode && [PM_GOPAY, PM_QRIS, PM_SHOPEE].includes(transaction?.payment_method || 0) && <img src={qrCode} alt={'qr'} style={{width:'250px',height:'250px'}}/> }
						{ qrCode && [PM_VA_BCA, PM_VA_BNI, PM_VA_BRI].includes(transaction?.payment_method || 0) && <>
              <div className={'d-flex justify-content-between align-items-center w-100'}>
                <span>No. Rekening</span>
                <div className={'d-flex align-items-center'}>
                  <span className={'fw-bold fs-5 me-2'}>{qrCode}</span>
                  <BiCopy size={'1.25rem'} onClick={copyVa}/>
                </div>
              </div>
            </>}
					</div>
					<div className={'d-flex flex-column align-items-center'} style={{gap:'10px'}}>
						<div className={'d-flex justify-content-center w-100'} style={{gap:'10px'}}>
							<Button outlinePrimary disabled={!canCheck} onClick={checkTrans} block>Verifikasi</Button>
							<Button danger onClick={()=>showModalCancel(true)} block>Batalkan</Button>
						</div>
						{[PM_GOPAY].includes(transaction?.payment_method || 0) && <MobileView className="w-100">
              <Button primary onClick={openApp} block>Buka App</Button>
            </MobileView>}
					</div>
				</div>
				<div className={'border border-bottom w-100 my-3'}></div>
				<h5 className={'text-muted fw-bold mb-4'}>Instruksi</h5>
				<ol style={{margin:0,paddingLeft:'1rem'}}>
					<li>Buka Aplikasi Gojek</li>
					<li>Pindai QR Code</li>
					<li>Konfirmasi Pembayaran di Aplikasi</li>
					<li>Pembayaran Selesai</li>
				</ol>
			</div>
		</div>
		<Modal show={modalCancel} onHide={()=>{ showModalCancel(false) }} centered={true}>
			<Modal.Body>Anda yakin akan membatalkan transaksi ini?</Modal.Body>
			<Modal.Footer>
				<Button secondary onClick={()=>{ showModalCancel(false) }}>
					Tutup
				</Button>
				<Button danger className={'min-w-75px'} onClick={cancel}>
					Ya
				</Button>
			</Modal.Footer>
		</Modal>
	</>
}

export default Wait
