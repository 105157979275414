import { BsStarHalf, BsStarFill } from 'react-icons/bs'
import React from 'react'
import {Link, useHistory} from 'react-router-dom'
import { formatNumber } from '../../../utils/common'
interface propsCourseRatings{
  rating: number
  totalReviews: number
  courseId: number
}
const CourseRatings = (props: propsCourseRatings) => {
  const history = useHistory();
  const allRating = Math.ceil(props.rating);
  const fullRating = Math.floor(props.rating);
  const haveHalf = props.rating % fullRating !== 0;
  const stars = new Array(allRating).fill(null).map((_, i) => i + 1);
  return (<>
    <div className={'cd-rating d-flex flex-column align-items-center'}>
      <div className='d-flex mb-3'>
        {
          stars.map((s, index) => {
            return index === (allRating - 1) && haveHalf ?
              <BsStarHalf key={`r-${index}-${new Date()}`} size='1.5rem' className={`fill-secondary ${index !== (allRating - 1) ? 'me-1' : ''}`} /> :
              <BsStarFill key={`r-${index}-${new Date()}`} size='1.5rem' className={`fill-secondary ${index !== (allRating - 1) ? 'me-1' : ''}`} />
          })
        }
      </div>
      <a className="text-decoration-underline cursor-pointer" onClick={()=>{
        if(props.totalReviews > 0){
          history.push(`/course/reviews/${props.courseId}`)
        }
      }}>{props.rating} ({formatNumber(props.totalReviews)} ulasan)</a>
    </div>
  </>)
}

export default CourseRatings;

export const CourseRatingsFlat = (props: propsCourseRatings) => {
  const isFull = props.rating === 5;

  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-3 w-100'>
        <div className='d-flex align-items-center'>
          { isFull ? <BsStarFill className='fill-secondary me-2'  /> : <BsStarHalf className='fill-secondary me-2'  /> }
          <span className='fw-bold me-2'>{props.rating}</span>
          <span className='fw-bold'>({formatNumber(props.totalReviews)} Ulasan)</span>
        </div>
      </div>
    </>
  );
}
