import React from 'react'
import { CourseCategory } from '../../../typings/course'
import './course-category-filter.scss';
interface CourseCategoryProps{
  categories: CourseCategory[]
  category?: number | null
  onClick: Function

}
const CourseCategoryFilter: React.FC<CourseCategoryProps> = (props) => {
  const {categories, category, onClick} = props;
  return (
    <div className='category-list my-2'>
      {
        categories.map((cat,index)=>{
          return (
            <div
              className={`category-list-item ${category === cat.id?'active':''}`}
              key={`cat-${index}`}
              onClick={()=>{
                onClick(cat.id);
              }}
            >{cat.name}</div>
          )
        })
      }
    </div>
  );
};
export default CourseCategoryFilter;
