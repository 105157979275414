import React, {useEffect, useState} from "react";
import {RouteComponentProps, useHistory, useParams} from "react-router-dom";
import './checkout.scss'
import {FaDotCircle, FaRegCircle} from 'react-icons/fa'
import gopayIcon from '../../assets/images/logo/gopay.png'
import shopeeIcon from '../../assets/images/logo/shopeepay.png'
import qrisIcon from '../../assets/images/logo/qris.png'
import ccIcon from '../../assets/images/logo/cc.png'
import bcaIcon from '../../assets/images/logo/bca.png'
import bniIcon from '../../assets/images/logo/bni.png'
import briIcon from '../../assets/images/logo/bri.png'
import {
	PAYMENT_PENDING,
	PAYMENT_SUCCESS,
	PM_CC,
	PM_GOPAY,
	PM_QRIS,
	PM_SHOPEE,
	PM_VA_BCA,
	PM_VA_BNI,
	PM_VA_BRI
} from "../../constant/payment";
import {useDispatch} from "react-redux";
import {Course as CourseType} from "../../typings/course";
import {PaymentCheckoutDetail as PaymentCheckoutDetailType} from "../../typings/payment";
import {useQuery} from "react-query";
import {authenticatedRequestRaw} from "../../services/api.service";
import {LOADING_UI, UNLOADING_UI} from "../../redux/types";
import CheckoutBottom from "./partials/checkout-bottom";
import {Modal} from "react-bootstrap";
import {PaymentCheckout} from "../../typings/payment";
import AuthHeader from "../../components/Layout/Auth/Header";
import Header from "../../components/Layout/Header/header";
const Checkout: React.FC<RouteComponentProps> = (props) => {
	const [paymentMethod, setPaymentMethod] = useState<number | null>(null)
	const history  = useHistory();
	const dispatch = useDispatch();
	const params:any  = useParams();
	const [showModalQR, setShowModalQR] = useState<boolean>(false);
	const [courseId, setCourseId] = useState<number | null>(null);
	const [course, setCourse] = useState<CourseType | null>(null);
	const { data, refetch, isFetching } = useQuery<PaymentCheckoutDetailType>('payment-checkout', async () => {
		const response = await authenticatedRequestRaw('GET','/payment/checkout/' + courseId)
		return response?.data?.data as PaymentCheckoutDetailType
	}, {enabled: false})
	useEffect(() => {
		if(isNaN(params?.id)){
			history.push('/dashboard')
		}
		setCourseId(Number(params?.id));
	},[]) // eslint-disable-line react-hooks/exhaustive-deps
	useEffect(() => {
		if(courseId){
			refetch().then((res) => {
				const data = res?.data;
				dispatch({ type: UNLOADING_UI })
				setCourse(data?.course || null)
				if(data?.isPayment){
					if(data?.pmStatus === PAYMENT_SUCCESS){
						history.push(`/course/${courseId}`)
					}else if(data?.pmStatus === PAYMENT_PENDING){
						history.push(`/payment/wait/${data?.uid}`)
					}
				}
			});
		}
	},[courseId]);
	useEffect(() => {
		if(isFetching){
			dispatch({ type: LOADING_UI })
		}else{
			dispatch({ type: UNLOADING_UI })
		}
	},[isFetching, dispatch])
	useEffect(() => {
		if(paymentMethod){
			getPaymentMethodPrice()
		}
	},[paymentMethod])
	const selectPaymentMethod = (pm: number|null) => {
		setPaymentMethod(pm)
	}
	const isPMSelected = (pm: number|null) => {
		return paymentMethod === pm ? <FaDotCircle size={'1.25rem'} /> :<FaRegCircle size={'1rem'} />;
	}
	const getPaymentMethodPrice = async () => {
		if(course){
			dispatch({ type: LOADING_UI })
			const response = await authenticatedRequestRaw('POST','/payment/change-pm',{
				payment_method: paymentMethod,
				course_id: course?.id,
			})
			const data = response?.data?.data as any
			setCourse({...course, price: data?.totalPrice})
			dispatch({ type: UNLOADING_UI })
		}

	}

	const pay = async () => {
		if(course){
			dispatch({ type: LOADING_UI })
			const response = await authenticatedRequestRaw('POST','/payment/create',{
				payment_method: paymentMethod,
				course_id: course?.id,
			})
			const data = response?.data?.data as PaymentCheckout
			history.push(`/payment/wait/${data.uid}`)
			dispatch({ type: UNLOADING_UI })
		}

	}

	return <>
		<Header title={"Pembayaran"} onBack={() => {
			history.push(`/course/${courseId}`)
		}} />
		<div className={'main-content w-100'}>
			<div className={'d-flex w-100 flex-column align-items-start checkout-container pb-6 pt-6'}>
				<h2>{course?.name}</h2>
				<small className={'mb-3'}>Silahkan pilih metode pembayaran</small>
				<div className={'pm-list d-flex flex-column w-100'}>
					<div className={'text-muted'}>E-Wallet</div>
					<div className={'pm-list-item d-flex justify-content-between align-items-center'}
					     onClick={() => selectPaymentMethod(PM_GOPAY)}>
						<div className={'pm-info d-flex align-items-center'}>
							<img src={gopayIcon}/>
							<span className={'fw-bold'}>Gopay</span>
						</div>
						{ isPMSelected(PM_GOPAY) }
					</div>
					{/*<div className={'pm-list-item d-flex justify-content-between align-items-center'}*/}
					{/*     onClick={() => selectPaymentMethod(PM_SHOPEE)}>*/}
					{/*	<div className={'pm-info d-flex align-items-center'}>*/}
					{/*		<img src={shopeeIcon}/>*/}
					{/*		<span className={'fw-bold'}>Shopee</span>*/}
					{/*	</div>*/}
					{/*	{ isPMSelected(PM_SHOPEE) }*/}
					{/*</div>*/}
					{/*<div className={'pm-list-item d-flex justify-content-between align-items-center'}*/}
					{/*     onClick={() => selectPaymentMethod(PM_QRIS)}>*/}
					{/*	<div className={'pm-info d-flex align-items-center'}>*/}
					{/*		<img src={qrisIcon}/>*/}
					{/*		<span className={'fw-bold'}>QRIS</span>*/}
					{/*	</div>*/}
					{/*	{ isPMSelected(PM_QRIS) }*/}
					{/*</div>*/}
					<div className={'text-muted'}>Bank Transfer</div>
					<div className={'pm-list-item d-flex justify-content-between align-items-center'}
					     onClick={() => selectPaymentMethod(PM_VA_BCA)}>
						<div className={'pm-info d-flex align-items-center'}>
							<img src={bcaIcon}/>
							<span className={'fw-bold'}>BCA Virtual Account</span>
						</div>
						{ isPMSelected(PM_VA_BCA) }
					</div>
					<div className={'pm-list-item d-flex justify-content-between align-items-center'}
					     onClick={() => selectPaymentMethod(PM_VA_BNI)}>
						<div className={'pm-info d-flex align-items-center'}>
							<img src={bniIcon}/>
							<span className={'fw-bold'}>BNI Virtual Account</span>
						</div>
						{ isPMSelected(PM_VA_BNI) }
					</div>
					{/*<div className={'pm-list-item d-flex justify-content-between align-items-center'}*/}
					{/*     onClick={() => selectPaymentMethod(PM_VA_BRI)}>*/}
					{/*	<div className={'pm-info d-flex align-items-center'}>*/}
					{/*		<img src={briIcon}/>*/}
					{/*		<span className={'fw-bold'}>BRI Virtual Account</span>*/}
					{/*	</div>*/}
					{/*	{ isPMSelected(PM_VA_BRI) }*/}
					{/*</div>*/}
					{/*<div className={'text-muted'}>Credit Card</div>*/}
					{/*<div className={'pm-list-item d-flex justify-content-between align-items-center'}*/}
					{/*     onClick={() => selectPaymentMethod(PM_CC)}>*/}
					{/*	<div className={'pm-info d-flex align-items-center'}>*/}
					{/*		<img src={ccIcon}/>*/}
					{/*		<span className={'fw-bold'}>Kartu Kredit</span>*/}
					{/*	</div>*/}
					{/*	{ isPMSelected(PM_CC) }*/}
					{/*</div>*/}
				</div>
			</div>
			{ course && <CheckoutBottom course={course} onPay={pay} paymentMethod={paymentMethod} /> }
		</div>
		<Modal show={showModalQR} onHide={() => setShowModalQR(false)} className={'modal-qr'} backdrop={'static'}>
			{/*<Modal.Header closeButton></Modal.Header>*/}
			<Modal.Body>
				<div className={'d-flex justify-content-center align-items-center'}>
					<div className={'d-flex flex-column align-items-center'}>
						{/*<img src={}*/}
					</div>
				</div>
			</Modal.Body>
		</Modal>
	</>
}
export default Checkout
