import InputSearch from "../../components/UI/InputSearch";
import CourseCategoryFilter from "../../components/Layout/Course/CourseCategoryFilter";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import useQueryParams from "../../hooks/useQueryParams";
import {Course, CourseCategory} from "../../typings/course";
import {useInfiniteQuery, useQuery} from "react-query";
import {authenticatedRequest, authenticatedRequestRaw} from "../../services/api.service";
import {LOADING_UI, UNLOADING_UI} from "../../redux/types";
import {RouteComponentProps} from "react-router-dom";
import {InitialStateUIType, InitialStateUserType} from "../../typings/state";
import {CompetencyTestType} from "../../typings/competency-test";
import CompetencyCard from "../../components/Layout/Competency/CompetencyCard";
import {useInView} from "react-intersection-observer";
import {ApiResponse} from "../../typings/api";
import CourseCard from "../../components/Layout/Course/CourseCard";
interface CompetencyTestProps extends RouteComponentProps{
	user: InitialStateUserType;
	UI: InitialStateUIType;
}
const CompetencyTest: React.FC<CompetencyTestProps> = (props) => {
	const { ref, inView } = useInView()
	const dispatch = useDispatch();
	const [querySearch, setQuerySearch] = useState<string | null>(null)
	const [sort, setSort] = useState<string>('')
	const [category, setCategory] = useState<number | null | undefined>(null)
	const queryParams = useQueryParams();
	const [categories, setCategories] = useState<CourseCategory[]>([])
	const { data: dataCategories, isLoading: loadingCategories } = useQuery('competency-test-categories', async () => {
		const response = await authenticatedRequestRaw('GET','/competency-test/categories')
		return response?.data?.data
	})
	const { data, fetchNextPage, isFetchingNextPage } = useInfiniteQuery<ApiResponse>(['competency-test-list-page',category, querySearch], async ({ pageParam = 1 }) => {
		try{
			const params: any = {
				page: pageParam,
			}
			if(category){
				params['category'] = category;
			}
			if(querySearch && querySearch !== ''){
				params['search'] = querySearch
			}
			const response = await authenticatedRequest('POST','/competency-test/list',params)
			return response as ApiResponse
		}catch (e) {
			return [] as unknown as ApiResponse
		}
	},{
		getPreviousPageParam: (firstPage: any) => firstPage?.meta?.previous_page_url ? Number(firstPage.meta.previous_page_url.replace('/?page=','')) : undefined,
		getNextPageParam: (firstPage: any) => firstPage?.meta?.next_page_url ? Number(firstPage.meta.next_page_url.replace('/?page=','')) : undefined,
		enabled: false
	})

	const inputRef: React.RefObject<HTMLElement> = React.createRef();
	useEffect(() => {
		if(dataCategories){
			setCategories([{
				id:null,
				name: 'Semua'
			},...dataCategories])
		}
	},[dataCategories])
	useEffect(() => {
		if(querySearch && category){
			fetchNextPage();
		}
	},[querySearch, category])
	useEffect(() => {
		if(loadingCategories || isFetchingNextPage){
			dispatch({ type: LOADING_UI })
		}else{
			dispatch({ type: UNLOADING_UI })
		}
	},[loadingCategories, isFetchingNextPage, dispatch])
	useEffect(() => {
		if(category!==undefined){
			setTimeout(()=>{
				fetchNextPage()
			},500)
		}
	},[category])
	useEffect(() => {
		setQuerySearch(queryParams.get('q'));
	}, [])
	useEffect(() => {
		if(inView){
			fetchNextPage();
		}
	},[inView])
	const handleQuerySearch = (value: string) => {
		setQuerySearch(value);
	}
	const clearQuerySearch = () => {
		setQuerySearch('');
		setTimeout(()=>{
			fetchNextPage({
				pageParam: 1
			})
		},500)
	}
	const enterSearch = () => {
		inputRef?.current?.blur();
		fetchNextPage({
			pageParam: 1
		})
	}
	const setCategoryFilter = (catId: number | null) => {
		setCategory(catId)
	}
	return <>
		<InputSearch
			placeholder={'Cari Tes Kompetensi'}
			onChange={handleQuerySearch.bind(this)}
			onEnter={enterSearch.bind(this)}
			defaultValue={querySearch}
			onClear={clearQuerySearch.bind(this)}
			ref={inputRef}
		/>
		<CourseCategoryFilter categories={categories} category={category} onClick={(catId: number | null) => {
			setCategoryFilter(catId);
		}} />
		{ data?.pages && (
			<>
				{data.pages[0]?.data?.length > 0 && (
					<>
						{data?.pages.map((l: any,idx1) => (
							<React.Fragment key={`ct-more-pages-${idx1}`}>
								{l.data.map((c:CompetencyTestType) => {
									return (
										<CompetencyCard key={c.id} {...c}></CompetencyCard>
									)
								})}
							</React.Fragment>
						))}
						<span ref={ref} className={'edge-infinite'}></span>
					</>
				)}
			</>
		)
		}
		<div className='d-flex w-100 mb-5'></div>
	</>
}

export default CompetencyTest
