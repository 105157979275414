import "./header-auth.scss";
import logoKelase from '../../../assets/images/logo/logo-kelase-home.png';
import { FaChevronLeft } from 'react-icons/fa'
interface IAuthHeader{
  back?: Function,
  className?:string
}
const AuthHeader = (props: IAuthHeader) => {
  const back = props.back;
  let className = `header-auth`
  if(props.className){
    className+= ` ${props.className}`;
  }
  return (
    <div className={className}>
      {back?<a className={'back'} onClick={(event)=>{
        back();
      }}>
        <FaChevronLeft size={'1.5rem'} />
      </a>:null}
      <img src={logoKelase} alt={'logo'}/>
    </div>
  )
}
export default AuthHeader;
