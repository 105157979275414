import React, {useEffect, useState} from "react";
import {RouteComponentProps, useHistory, useParams} from "react-router-dom";
import {InitialStateUIType, InitialStateUserType} from "../../typings/state";
import {CompetencyTestDetailType} from "../../typings/competency-test";
import {useDispatch} from "react-redux";
import {useQuery} from "react-query";
import {authenticatedRequest, authenticatedRequestRaw} from "../../services/api.service";
import {toast} from "react-toastify";
import Header from "../../components/Layout/Header/header";
import {LOADING_UI, UNLOADING_UI} from "../../redux/types";
import Countdown from "react-countdown";
import Quiz from "./quiz";
interface CompetencyTestProps extends RouteComponentProps{
	user: InitialStateUserType;
	UI: InitialStateUIType;
}
const TestDetail: React.FC<CompetencyTestProps> = (props) => {
	const history  = useHistory();
	const dispatch = useDispatch();
	const params:any  = useParams();
	const [testId, setTestId] = useState<number | null>(null);
	const [isTimeOver, setIsTimeOver] = useState<boolean>(false);
	const [competency, setCompetency] = useState<CompetencyTestDetailType | null>(null)
	const { data, refetch, isFetching } = useQuery('competencyTestDetail', async () => {
		try{
			const response = await authenticatedRequestRaw('GET','/competency-test/test/' + testId)
			return response?.data?.data
		}catch (e: any) {
			toast.error(e?.response?.data?.message ?? 'Terjadi Kesalahan. Silahkan coba lagi beberapa saat', {
				position: toast.POSITION.TOP_CENTER
			});
			history.push(`/competency/${testId}`)
		}
		return null
	}, {enabled: false})
	useEffect(() => {
		if(isNaN(params?.id)){
			history.push('/dashboard')
		}
		setTestId(Number(params?.id));
	},[]) // eslint-disable-line react-hooks/exhaustive-deps
	useEffect(() => {
		if(testId){
			refetch();
		}
	},[testId]);
	useEffect(() => {
		if(data){
			// @ts-ignore
			setCompetency(data)
		}
	},[data]);
	useEffect(() => {
		if(isFetching){
			dispatch({ type: LOADING_UI })
		}else{
			dispatch({ type: UNLOADING_UI })
		}
	},[isFetching, dispatch])
	return <>
		<Header title={competency?.title} onBack={() => {
			history.push(`/competency/${testId}`)
		}} />
		<div className={'main-content w-100'}>
			{
				competency ? <div className='w-100 d-flex p-3 bg-danger text-white position-sticky justify-content-center fw-bold mb-3 rounded-1'>
					<Countdown date={Date.now() + competency.remaining} onComplete={()=>{
						setIsTimeOver(true)
					}} />
				</div> : null
			}
			{ competency && <Quiz activity={competency} timeOver={isTimeOver} /> }
		</div>
	</>
}

export default TestDetail
