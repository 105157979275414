import './course-detail-bottom.scss';
import Button from '../../../components/UI/Button'
import {CourseDetail} from "../../../typings/course";
import {useHistory} from "react-router-dom";
interface ICourseDetailBottom {
  course: CourseDetail
}
const CourseDetailBottom = ({course}: ICourseDetailBottom) => {
  const history = useHistory();
  const goToCheckout = () => {
    if(course.isPayment && course?.uid){
      history.push(`/payment/wait/${course.uid}`)
    }else{
      history.push(`/payment/checkout/${course.id}`)
    }
  }
  return (
    <>
      <div className='cd-bottom-container'>
        <div className='cd-bottom-content'>
          <Button primary={true} block={true} onClick={goToCheckout}>
            {course.isPayment? (<>Lanjutkan Pembayaran</>) : (<>Ikuti Kursus (Rp{course.price.toLocaleString('id-ID')})</>)}
          </Button>
        </div>
      </div>
    </>
  )
}
export default CourseDetailBottom;
