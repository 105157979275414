import './competency-card.scss';
import {BiBook, BiQuestionMark} from 'react-icons/bi'
import { useHistory } from 'react-router-dom'
import React from "react";
import {CompetencyTestType} from "../../../typings/competency-test";
const CompetencyCard: React.FC<CompetencyTestType> = (props: CompetencyTestType) => {
	const history = useHistory();
	const detail = (id: number) => {
		history.push(`/competency/${id}`)
	}

	const MyCardInfo = () => {
		if(props?.isExpired){
			return (<div className='badge bg-danger' style={{whiteSpace: 'normal'}}>Tes ini sudah kadaluarsa</div>)
		}else if(props?.isOnProgress){
			return (<div className='badge bg-info' style={{whiteSpace: 'normal'}}>Anda masih mengerjakan test ini</div>)
		}else{
			return (
				<div className='d-flex align-items-center' style={{ gap: '16px' }}>
					<span>Nilai:</span>
					<span className='fw-bold fs-1'>{props.score ?? 0}</span>
				</div>
			)
		}
	}

	return (
		<div className='card-course' onClick={() => detail(props.id)}>
			<div className='cc-content'>
				<div className='cc-image'>
					<img src={props?.image ?? 'https://placehold.co/300x300'}
					     alt={'logo'}
					/>
				</div>
				<div className='cc-content-info'>
					<span className="badge course-category-label mb-1">{props.category}</span>
					<h5 className='fw-bold mb-3 mt-1 cc-title'>{props.title}</h5>
					{
						props?.isMy ?
							<MyCardInfo />
							:(
								<>
									<span className='fs-7'>{props.duration} Menit</span>
									<span className='fs-7'>{props.totalQuestions} Pertanyaan</span>
								</>
							)
					}
				</div>
			</div>
		</div>
	)
}
export default CompetencyCard;
